<template>
  <f7-page hide-bars-on-scroll :page-content="false">
    <f7-navbar no-shadow sliding :back-link="$t('common.backButton')" :back-link-show-text="false">
      <f7-nav-title sliding>{{ this.title }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar tabbar top no-shadow>
      <f7-link tab-link="#claimWithQRCode" tab-link-active :text="$t('acceptcash.scanQrCode')"
        icon-aurora="f7:qrcode_viewfinder" icon-ios="f7:qrcode_viewfinder" icon-md="f7:qrcode_viewfinder"
        style="text-transform: lowercase"></f7-link>
      <f7-link tab-link="#claimWithavocashNumber" :text="$t('acceptcash.useAccount')" icon-aurora="f7:keyboard_fill"
        icon-ios="f7:keyboard_fill" icon-md="material:dialpad" style="text-transform: lowercase"></f7-link>
      <f7-link tab-link="#claimWithNewQRCode" :text="$t('acceptcash.createQrCode')" icon-ios="f7:qrcode"
        icon-aurora="f7:qrcode" icon-md="f7:qrcode" style="text-transform: lowercase"></f7-link>
    </f7-toolbar>
    <f7-tabs swipeable animated>
      <f7-tab id="claimWithQRCode" tab-active>
        <scanQRToClaim-page />
      </f7-tab>
      <f7-tab id="claimWithavocashNumber">
        <accountNumberToClaim-page />
      </f7-tab>
      <f7-tab id="claimWithNewQRCode">
        <claimWithNewQRCode-page />
      </f7-tab>
    </f7-tabs>
  </f7-page>
</template>
<script>
import ScanQRToClaim from "./acceptcashWithQRCodeScan.vue";
import AccountNumberToClaim from "./acceptcashWithAccountNumber.vue";
import ClaimWithNewQRCode from "./acceptcashWithNewQRCode.vue";
import Navbarright from "../components/navbarright.vue";
import { mapGetters, mapState } from "vuex";
import { f7, theme } from "framework7-vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  props: {
    f7router: Object,
  },
  components: {
    "scanQRToClaim-page": ScanQRToClaim,
    "accountNumberToClaim-page": AccountNumberToClaim,
    "claimWithNewQRCode-page": ClaimWithNewQRCode,
    navbarright: Navbarright,
  },
  data() {
    return { theme, f7, title: "Accept cash" };
  },
  mounted() {
    if (isDeskTop == false) {
      f7.$("div.page").addClass("no-toolbar");
    }
  },
  methods: {},
  computed: { ...mapState("auth", ["isDeskTop"]) },
};
</script>
