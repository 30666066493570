<template>
  <f7-popup
    @popup:open="updateDocuments()"
    class="groupDocumentsViewAddPopup"
    style="--f7-sheet-bg-color: #fff"
    :tablet-fullscreen="true"
    :params="{
      backdrop: false,
      animate: true,
    }"
  >
    <f7-view>
      <f7-page
        name="groupDocuments"
        hide-navbar-on-scroll
        ptr
        :ptr-mousewheel="true"
        @ptr:refresh="loadMore"
      >
        <f7-navbar sliding no-shadow>
          <f7-nav-title v-if="this.isTablet">{{ $t("common.documents") }}</f7-nav-title>
          <f7-searchbar
            class="searchbar-documents"
            search-container=".search-document"
            :backdrop="false"
            :placeholder="$t('common.search')"
            search-in=".demo-facebook-avatar, .demo-facebook-name, .message-text-footer, .document_desc"
            style="max-width: 600px; margin: auto"
          >
          </f7-searchbar>
          <f7-nav-right>
            <f7-link
              popup-close=".groupDocumentsViewAddPopup
            "
            >
              <f7-icon
                ios="f7:multiply"
                aurora="f7:multiply"
                md="material:close"
              ></f7-icon>
            </f7-link>
          </f7-nav-right>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-list
              dividers
              strong-ios
              transaction-list
              class="searchbar-not-found"
              v-if="this.sharedDocList && this.sharedDocList.length === 0"
            >
              <f7-list-item :title="$t('common.nothingFound')"> </f7-list-item>
            </f7-list>
            <f7-list
              v-else
              dividers-ios
              strong-ios
              media-list
              class="search-document searchbar-found"
            >
              <f7-list-group>
                <f7-card
                  class="demo-facebook-card"
                  v-for="(document, index) in this.sharedDocList"
                  :key="index"
                >
                  <f7-card-header class="no-border">
                    <div class="demo-facebook-avatar">{{ document.tag }}</div>
                    <div class="demo-facebook-name">{{ document.addedBy }}</div>
                    <div class="demo-facebook-date">
                      {{ this.$dayjs(document.created).fromNow() }}
                    </div>
                  </f7-card-header>
                  <f7-card-content :padding="false">
                    <swiper-container
                      v-if="document.fileList && document.fileList.length > 0"
                      class="dropbox margin-horizontal padding-top"
                      :slidesPerView="parseInt(f7.width / 150) + 1"
                      :spaceBetween="20"
                      :navigation="
                        this.isTablet && document.fileList.length > 0 ? true : false
                      "
                      :params="{
                        disableOnInteraction: true,
                      }"
                    >
                      <f7-photo-browser
                        :photos="this.photoList()"
                        ref="popup"
                      ></f7-photo-browser>

                      <swiper-slide
                        v-for="(imageItem, index) in document.fileList"
                        :key="index"
                      >
                        <a link="#" @click="$refs.popup.open()">
                          <img
                            v-if="
                              imageItem.fileType !== null &&
                              imageItem.fileType === 'image'
                            "
                            :src="imageItem.url"
                            alt="file to upload"
                          />
                          <f7-block-footer
                            class="no-margin-vertical message-text-footer"
                            >{{ imageItem.fileName }}</f7-block-footer
                          ></a
                        >
                        <a link="#" @click="$refs.popup.open()">
                          <video
                            v-if="imageItem.fileType === 'video'"
                            controls
                            style="
                              display: block;
                              max-width: 100%;
                              height: auto;
                              width: auto;
                            "
                          >
                            <source
                              :src="imageItem.url"
                              :type="imageItem.fileType"
                              preload="metadata"
                            />
                            {{ $t("tamtammessage.yourBrowserDoesntsupportvideo") }}</video
                          ><br />
                          <f7-block-footer
                            class="no-margin-vertical message-text-footer"
                            >{{ imageItem.fileName }}</f7-block-footer
                          >
                        </a>

                        <span v-if="imageItem.msgType === 'audio'">
                          <audio controls>
                            <source :src="imageItem.url" :type="imageItem.fileType" />
                            {{ $t("tamtammessage.yourBrowserDoesntsupportaudio") }}
                          </audio>
                          <f7-block-footer
                            class="no-margin-vertical message-text-footer"
                            >{{ imageItem.fileName }}</f7-block-footer
                          >
                        </span>
                        <iframe
                          v-else
                          :src="imageItem.url"
                          :title="imageItem.fileName"
                          class="margin-half"
                          allowfullscreen
                          style="width: 100%"
                        ></iframe>
                        <!-- <f7-block-footer>{{ imageItem.name }}</f7-block-footer> -->
                        <f7-link
                          v-if="
                            document.addedBy === userAccount.userId || currentUserIsAdmin
                          "
                          raised
                          :style="{
                            position: 'absolute',
                            left: '8px',
                            top: '8px',
                            color: 'red',
                          }"
                          icon-f7="multiply_circle_fill"
                          @click="deleteSharedFile(imageItem.id)"
                        ></f7-link>

                        <f7-link
                          v-if="imageItem.fileType !== 'image'"
                          raised
                          :style="{
                            position: 'absolute',
                            right: '-8px',
                            top: '8px',
                          }"
                          icon-f7="arrow_up_right_square"
                          @click="viewOtherFiles(imageItem)"
                        ></f7-link>
                      </swiper-slide>
                    </swiper-container>
                  </f7-card-content>
                  <f7-card-footer class="no-border">
                    <f7-list-item accordion-item :title="$t('common.description')">
                      <f7-accordion-content>
                        <f7-block>
                          <p class="document_desc" v-html="document.description"></p>
                        </f7-block>
                      </f7-accordion-content>
                    </f7-list-item>
                  </f7-card-footer>
                </f7-card>
              </f7-list-group>
            </f7-list>
            <f7-list v-if="this.isAdmin">
              <f7-list-group>
                <f7-list-item
                  :header="$t('common.select')"
                  class="selectProjects"
                  smart-select
                  :smart-select-params="{
                    openIn: !isTablet || f7.device.cordova ? 'sheet' : 'popup',
                    searchbar: true,
                    closeOnSelect: true,
                    searchbarPlaceholder: $t('common.search'),
                  }"
                >
                  <select name="selectproject" v-model="projectId" required validate>
                    <option
                      v-for="(project, index) in projectList"
                      :key="index"
                      :value="project.id"
                    >
                      {{ project.name }}
                    </option>
                  </select>
                </f7-list-item>
                <f7-list-input
                  floating-label
                  :label="$t('common.tag')"
                  type="text"
                  name="tag"
                  v-model="tag"
                  required
                  validate
                  clear-button
                />
                <f7-block-title block-title-medium class="no-margin-bottom"
                  >{{ $t("common.description") }}:</f7-block-title
                >
                <f7-text-editor
                  :placeholder="$t('common.description')"
                  :value="description"
                  @texteditor:change="(v) => (description = v)"
                  resizable
                  required
                  validate
                  type="texteditor"
                  :mode="'toolbar'"
                  :style="
                    f7.device.cordova || !isTablet ? '--f7-text-editor-height: 300px' : ''
                  "
                ></f7-text-editor>

                <f7-list-item
                  @click="attachFileOpenActions()"
                  link="#"
                  class="attachFileButton"
                  :title="$t('common.uploadFiles')"
                >
                </f7-list-item>
                <div v-if="documentsUrl && documentsUrl.length > 0">
                  <span v-for="(docItem, index) in documentsUrl" :key="index">
                    <f7-list-item :title="docItem.name">
                      <template #media>
                        <f7-link
                          @click="removeDoc(index)"
                          raised
                          color="red"
                          icon-f7="multiply_circle_fill"
                        ></f7-link>
                      </template>
                    </f7-list-item>
                  </span>
                </div>
              </f7-list-group>

              <f7-button
                ripple
                raised
                fill
                large
                class="buttons"
                style="border-radius: 0px"
                color="red"
                @click="createSharedDocuments()"
                >{{ $t("common.save") }}</f7-button
              >
            </f7-list>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-view>
  </f7-popup>
</template>
<style scoped></style>
<script>
import {
  f7Page,
  f7Navbar,
  f7NavRight,
  f7NavTitle,
  f7Link,
  f7Icon,
  f7Block,
  f7List,
  f7ListGroup,
  f7ListItem,
  f7ListInput,
  f7TextEditor,
  f7Popup,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { attachDocs } from "../../js/mixins/attachDocs.js";
import { cordovaCamera } from "../../js/mixins/cordovaCamera.js";
import editDocs from "./../editDocs.vue";
import _ from "lodash";

export default {
  props: {
    f7route: Object,
    f7router: Object,
    projectList: Array,
    entityType: String,
    entityId: String,
    currentUser: Object,
    isAdmin: Boolean,
    entity: Object,
  },
  mixins: [attachDocs, cordovaCamera],
  components: {
    editDocs,
    f7Page,
    f7Navbar,
    f7NavRight,
    f7NavTitle,
    f7Link,
    f7Icon,
    f7Block,
    f7List,
    f7ListGroup,
    f7ListItem,
    f7ListInput,
    f7TextEditor,
    f7Popup,
  },
  data() {
    return {
      theme,
      f7,
      description: "",
      tag: "",
      projectId: "",
    };
  },

  mounted() {},
  methods: {
    updateDocuments() {
      this.$store.dispatch("document/getSharedDocumentList", this.entityId);
    },

    loadMore(done) {
      setTimeout(() => {
        this.visibleItem += 30;
        this.$store.dispatch(
          "document/getSharedDocumentList",
          this.entityId
          // , {
          //     maxNumberOfItem: this.visibleItem,
          // }
        );
        done();
      }, 1000);
    },
    deleteSharedDocuments(docId) {
      this.$store.dispatch("document/deleteSharedDocuments", {
        docId,
        isAdmin: this.isAdmin,
      });
    },
    deleteSharedFile(fileId) {
      this.$store.dispatch("document/deleteSharedFile", {
        fileId,
        isAdmin: this.isAdmin,
      });
    },
    async createSharedDocuments() {
      try {
        const data = {
          entityId: this.entityId,
          projectId: this.projectId,
          userId: this.userAccount.userId,
          addedBy: this.userAccount.name,
          description: this.description,
          tag: this.tag,
        };
        const forms = new FormData();
        forms.append("sharedDocument", data);
        for (const content of this.documentsUrl) {
          forms.append("files", content.file);
        }
        const response = await this.$store.dispatch(
          "document/createSharedDocuments",
          forms
        );
        if (response.status === 200) {
          this.$f7.dialog.alert(this.$t("common.messageSent"));
          this.$f7.dialog.close();
        }
      } catch (error) {
        this.$f7.dialog.alert(this.$t("common.messageNotSent"));
      }
    },
  },
  computed: {
    ...mapState("auth", [
      "profile",
      "isDeskTop",
      "isTablet",
      "locale",
      "documentsUrl",
      "document",
    ]),
    ...mapState("account", ["userDetail", "userAccount", "users"]),
    ...mapState("document", ["sharedDocList"]),
    ...mapState("location", ["destinationCity", "country"]),
    countries() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
