import { f7, theme } from "framework7-vue";
import { utils } from "framework7";
import _ from "lodash";
import { mapGetters, mapState } from "vuex";
export const messageOptionsMixin = {
  methods: {
    reducePageWidth() {
      if (this.isTablet == true) {
        f7.$(
          ".view-master-detail .page-master-detail, .view-master-detail .navbar-master-detail"
        ).css({
          width: "calc(100% - 2*var(--f7-page-master-width))",
          // animation: "in 2s ease forwards 1",
        });
      }
      //         if (f7.panel.get(".contactDetailPanel")) {

      //   f7.panel.close(".contactDetailPanel");
      //   self.$store.dispatch("auth/setDocumentsUrl", "");

      //         }
    },
    resetPageWidth() {
      if (this.isTablet == true) {
        f7.$(
          ".view-master-detail .page-master-detail, .view-master-detail .navbar-master-detail"
        ).css({
          width: "calc(100% - var(--f7-page-master-width))",
          // animation: "out 2s ease forwards 1",
        });
      }
    },
    addParticipantGroupRightPanel() {
      f7.panel.open(".addParticipantGroupRightPanel");
    },
    openSendMessageLeftPanel() {
      if (this.users && this.users.length > 0) {
        this.getUserList();
      }
      f7.panel.toggle(".sendMessageLeftPanel", true);
    },
    attachFileOpenActions() {
      f7.actions.open(".attachFileForMobile");
    },

    // opencreateGroupeLeftPanel() {

    //   f7.panel.open(".createGroupeLeftPanel");
    //
    // },
    openContactDetailPanel() {
      f7.panel.toggle(".contactDetailPanel", true);
      this.$store.dispatch("auth/clearDocumentsUrl");
    },
    openMsgActionsSheet(message) {
      this.currentMessage = message;
      f7.sheet.open(".messageActionsSheet");
    },
    openMsgActionsPopover(message) {
      this.currentMessage = message;
    },

    openMessageOpenActions(message) {
      const self = this;
      this.currentMessage = message;
      console.log("message id ", message.id);
      const buttonsList = [
        {
          text: self.$t("tamtammessage.selectMessage"),
          icon: `<i class="f7-icons if-not-md color-deeppurple">square_list</i><i class="material-icons md-only color-deeppurple">playlist_add_check</i>`,
          color: "deeppurple",
          strong: true,
          onClick() {
            self.selectMsg(message);
          },
        },
        {
          text: self.$t("tamtammessage.copyMessage"),
          icon: `<i class="f7-icons if-not-md color-lime">square_on_square</i><i class="material-icons md-only color-lime">content_copy</i>`,
          color: "lime",
          strong: true,
          onClick() {
            self.copyMsg(message);
          },
        },
      ];
      if (message.senderId === self.profile.sub) {
        buttonsList.unshift({
          text: self.$t("tamtammessage.deleteMessageForAll"),
          icon: `<i class="f7-icons if-not-md color-red">trash_slash</i><i class="material-icons md-only color-red">delete_forever</i>`,
          color: "red",
          strong: true,
          onClick() {
            self.deleteForAll(message);
          },
        });
      }
      buttonsList.unshift(
        {
          text: self.$t("tamtammessage.forwardMessage"),
          icon: `<i class="f7-icons if-not-md color-gray">arrowshape_turn_up_right</i><i class="material-icons md-only color-gray" style="transform: scaleX(-1)">reply</i>`,
          color: "gray",
          strong: true,
          onClick() {
            self.forwardMsg(message);
          },
        },
        {
          text: self.$t("tamtammessage.deleteMessageForMe"),
          icon: `<i class="f7-icons if-not-md color-orange">trash</i><i class="material-icons md-only color-orange">delete</i>`,
          color: "deeporange",
          strong: true,
          onClick() {
            self.deleteForMe(message);
          },
        }
      );
      if (
        message.groupType !== "PERSONAL" &&
        message.senderId === self.profile.sub
      ) {
        buttonsList.unshift({
          text: self.$t("tamtammessage.replyPrivately"),
          icon: `<i class="f7-icons if-not-md color-teal">arrowshape_turn_up_left</i><i class="material-icons md-only color-teal">reply</i>`,
          color: "teal",
          strong: true,
          onClick() {
            self.replyMsgPrivately(message);
          },
        });
      }
      if (message.groupType !== "PERSONAL") {
        buttonsList.unshift(
          {
            text: self.$t("tamtammessage.reply"),
            icon: `<i class="f7-icons size-50 if-not-md color-primqry">arrowshape_turn_up_left_2</i><i class="material-icons size-50 md-only color-primary">reply_all</i>`,
            color: "primary",
            strong: true,
            onClick() {
              self.replyMsgGroup(message);
            },
          },
          {
            text: self.$t("tamtammessage.reply"),
            icon: `<i class="f7-icons if-not-md color-lightblue">arrowshape_turn_up_left</i><i class="material-icons md-only color-lightblue">reply</i>`,
            color: "lightblue",
            strong: true,
            onClick() {
              self.replyMsgPersonal(message);
            },
          }
        );
      }

      // if (!self.messageActionSheet) {
      console.log(
        "TARGET icon id ",
        f7.$(`.targetIcon${self.currentMessage.id}`)
      );
      self.messageActionSheet = f7.actions.create({
        convertToPopover: true,
        closeByOutsideClick: true,
        closeOnEscape: true,
        targetEl: f7.$(`.targetIcon${self.currentMessage.id}`),
        buttons: buttonsList,
      });
      // }

      // Open
      self.messageActionSheet.open();
    },

    messageBarVisible(message) {
      this.messageInScope = message;
      if (!this.isTablet && this.selectedMessages.length <= 0) {
        const messageNavBar = f7.$(`.messageOptionsNavBar${message.id}`);
        console.log(message);
        if (messageNavBar.css("display") === "none") {
          console.log("message", message);
          messageNavBar.css({ display: "block" });
          f7.$(".tamtamNav").css({ display: "none" });
          this.selectMsg(message);
        } else {
          messageNavBar.css({ display: "none" });
          f7.$(".tamtamNav").css({ display: "block" });
        }
        return;
      }
      if (!this.isTablet && this.selectedMessages.length >= 1) {
        this.selectMsg(message);
      } else if (
        this.isTablet &&
        f7.support.touch &&
        this.selectedMessages.length <= 0
      ) {
        this.currentMessage = message;
        this.$store.dispatch("message/messageIsSelected", true);
        f7.popover.open(
          ".messageActionsPopover",
          `.targetIcon${message.id}`,
          true
        );
      } else if (this.selectedMessages.length == 0 && this.messageIsSelected) {
        this.clearSelection();
      }
    },

    replyMsgGroup(message) {
      this.$store.dispatch("message/setReplyMsg", message);
    },
    replyMsgPersonal(message) {
      this.$store.dispatch("message/setReplyMsg", message);
    },
    removeReplyMessage() {
      this.$store.dispatch("message/setReplyMsg", "");
    },

    replyMsgPrivately(message) {
      const groupId = this.group.id ? this.group.id : message.senderid;
      this.replyMessage = message;
      f7.views.main.router.navigate(`/message/${groupId}/PERSONAL`, {
        reloadAll: true,
      });
    },
    deleteForMe(message) {
      console.log(this.$t("tamtammessage.deleteMessage"));
      f7.dialog.confirm(this.$t("tamtammessage.deleteMessage"), () => {
        this.$store.dispatch("message/deleteMessage", {
          messageId: message.id,
          forAll: false,
        });
        f7.messages.removeMessage(`#${message.id}`);
      });
    },

    deleteSelectedMsg() {
      f7.dialog.confirm(this.$t("tamtammessage.deleteSelectedMessages"), () => {
        for (const message of this.selectedMessages) {
          this.$store.dispatch("message/deleteMessage", {
            messageId: message.id,
            forAll: false,
          });
          f7.messages.removeMessage(`#${message.id}`);
        }
        this.clearSelection();
      });
    },

    deleteForAll(message) {
      f7.dialog.confirm(this.$t("tamtammessage.deleteMessageForAll"), () => {
        this.$store.dispatch("message/deleteMessage", {
          messageId: message.id,
          forAll: true,
        });
        f7.messages.removeMessage(`#${message.id}`);
      });
    },

    forwardAllMsg() {
      f7.panel.toggle(".transferMessageContactSelection", true);
    },
    selectRecipient(user) {
      const userIndexed = this.transferMsgRecipients.findIndex(
        (x) => x.id === user.id
      );
      console.log(userIndexed);
      console.log(this.transferMsgRecipients);
      if (userIndexed >= 0) {
        if (!this.isTablet || f7.support.touch) {
          f7.$(`.transferMsgRecipient${user.id}`).css({
            "background-color": "transparent",
          });
        }
        if (this.isTablet || !f7.support.touch) {
          f7.$(`input[name=transferMsg${user.id}]`).prop("checked", false);
        }
        this.transferMsgRecipients.splice(userIndexed, 1);
        this.deleteRecipientChip(user);
        return;
      }
      this.transferMsgRecipients.push(user);
      if (!this.isTablet || f7.support.touch) {
        f7.$(`.transferMsgRecipient${user.id}`).css({
          "background-color": "#87bfff",
        });
      }
      if (this.isTablet || !f7.support.touch) {
        f7.$(`input[name=transferMsg${user.id}]`).prop("checked", true);
      }
    },

    deleteRecipientChip(recipient) {
      const index = this.transferMsgRecipients.indexOf(recipient);
      this.transferMsgRecipients.splice(index, 1);
      if (!this.isTablet || f7.support.touch) {
        f7.$(`.transferMsgRecipient${recipient.id}`).css({
          "background-color": "transparent",
        });
      }
      if (this.isTablet || !f7.support.touch) {
        f7.$(`input[name=transferMsg${recipient.id}]`).prop("checked", false);
      }
    },

    transferMessages() {
      const dt = new Date();
      let messagesToSend = "";
      for (const recipient of this.transferMsgRecipients) {
        console.log("this.selectedMessages ", this.selectedMessages);
        for (const message of this.selectedMessages) {
          messagesToSend = {
            text: message.text,
            senderId: this.chatUser.userId,
            senderName:
              this.chatUser.name.split(" ")[0] ||
              this.chatUser.username ||
              this.chatUser.phoneNumber,
            sentAt: dt.toISOString(),
            readAt: "",
            attachment: "",
            replyingTo: "",
          };
          if (
            recipient.groupType &&
            (recipient.groupType === "GROUP" ||
              recipient.groupType === "SUBGROUP")
          ) {
            const messagesToSend0 = {
              receiverId: recipient.id,
              receiverName: recipient.name,
              groupType: recipient.groupType,
              type: "sent",
              status: "sent",
            };
            f7.utils.extend(messagesToSend, messagesToSend0);
            this.sendChatMessage(messagesToSend);
          } else if (recipient.userId !== "" && recipient.online !== "") {
            const currentGp = this.groupList.find(
              (x) =>
                x.name === `${recipient.userId}&${this.chatUser.userId}` ||
                x.name === `${this.chatUser.userId}&${recipient.userId}`
            );
            const messagesToSend1 = {
              receiverId: recipient.userId,
              receiverName: recipient.name,
              groupType: "PERSONAL",
              type: "sent",
              status: "sent",
              chatGroup: { id: currentGp.id },
            };
            f7.utils.extend(messagesToSend, messagesToSend1);
            this.sendChatMessage(messagesToSend);
          }
        }
        this.deleteRecipientChip(recipient);
        this.clearSelection();
        this.$store.dispatch("message/messageIsSelected", false);
      }
      f7.panel.close(".transferMessageContactSelection", true);
    },
    makeSelectable() {
      this.$store.dispatch("message/messageIsSelected", true);
      if (this.isTablet || !f7.support.touch) {
        f7.$(".message").css({ "margin-left": "100px" });
        f7.$(".multiMessagesSelect").css({ display: "block" });
      }
    },
    selectMsg(message) {
      console.log("selected message ", message);
      this.$store.dispatch("message/messageIsSelected", true);

      if (this.isTablet || !f7.support.touch) {
        f7.$(".message").css({ "margin-left": "100px" });
      }
      this.selectMessage(message);
    },
    selectMessage(message) {
      console.log("selected message2 ", this.selectedMessages);
      if (
        !(
          (message &&
            message.userMessageStatus.length > 0 &&
            message.userMessageStatus.find(
              (x) => x.userId === this.profile.sub
            ) !== null &&
            message.userMessageStatus.find((x) => x.userId === this.profile.sub)
              .status !== "deleted") ||
          (message && message.userMessageStatus.length == 0)
        )
      ) {
        return;
      }
      const targetCheckbox = f7.$(`input[name=checkbox${message.id}]`);
      const msgIndex = this.selectedMessages.findIndex(
        (x) => x.id === message.id
      );
      if (msgIndex >= 0) {
        f7.$(`span .selectedMsgClass${message.id}`).css({
          "background-color": "transparent",
        });
        if (this.isTablet || !f7.support.touch) {
          targetCheckbox.prop("checked", false);
          f7.$("message .message-received").removeClass("marging-left");
          f7.$(`span .selectedMsgClass${message.id}`).removeClass(
            "margin-vertical-half no-padding-vertical"
          );
        }

        this.$store.dispatch("message/selectMessage", {
          msg: message,
          action: "remove",
        });
        if (
          this.selectedMessages.length == 0 &&
          this.messageIsSelected == true
        ) {
          this.clearSelection();
        }
        return;
      }
      f7.$(`span .selectedMsgClass${message.id}`).css({
        "background-color": "#87bfff",
      });
      this.$store.dispatch("message/selectMessage", {
        msg: message,
        action: "add",
      });
      if (!(this.isTablet || !f7.support.touch)) {
        return;
      }
      targetCheckbox.prop("checked", true);
      f7.$(`span .selectedMsgClass${message.id}`).addClass(
        "margin-vertical-half no-padding-vertical"
      );
      f7.$("message .message-received").addClass("marging-left");
    },
    clearSelection() {
      if (this.selectedMessages && this.selectedMessages.length > 0) {
        for (const message of this.selectedMessages) {
          f7.$(`input[name=checkbox${message.id}]`).prop("checked", false);
          this.$store.dispatch("message/selectMessage", {
            msg: message,
            action: "remove",
          });
        }
        f7.$(`span .selectedMsg`).removeAttr("style");
        if (this.isTablet || !f7.support.touch) {
          f7.$(".message").removeAttr("style");
        }

        if (f7.$(".tamtamNav").css("display") === "none") {
          f7.$(".tamtamNav").css({ display: "block" });
        }
      }
      this.$store.dispatch("message/messageIsSelected", false);
    },
    copyMsg(message) {
      navigator.clipboard.writeText(message.text).then(() => {
        if (!this.toastIcon) {
          this.toastIcon = f7.toast.create({
            icon: theme.ios
              ? '<i class="f7-icons color-green">checkmark_alt_circle_fill</i>'
              : '<i class="material-icons color-green">check_circle_outline</i>',
            text: this.$t("common.copied"),
            position: "center",
            closeTimeout: 2000,
          });
        }
        // Open it
        this.toastIcon.open();
      });
    },
    forwardMsg(message) {
      this.$store.dispatch("message/messageIsSelected", true);
      console.log(this.messageIsSelected);
      if (this.isTablet) {
        f7.$("span .messsages").css({ "padding-left": "16px" });
        f7.$(".message").css({ "margin-left": "100px" });
      }
      this.selectMessage(message);
      setTimeout(() => {
        this.forwardAllMsg();
      }, 100);
    },
    messageActions(message) {
      if (this.messageIsSelected) {
        this.selectMessage(message);
      } else {
        this.messageBarVisible(message);
      }
    },
    messageDeskTopActions(message) {
      if (this.messageIsSelected && this.isTablet) {
        this.selectMessage(message);
      }
    },
  },
};

export const getusersMixin = {
  mounted() {
    if (this.$keycloak.authenticated && this.users === "") {
      this.$store.dispatch("account/getAllUsers");
    }
    if (f7.$("#autocomplete-dropdown-ajax-typeahead").val() === "") {
      this.$store.dispatch("account/setUser", "");
    }
    this.getUserList();

    const root = document.documentElement;
    if (f7.width >= 768) {
      root.style.setProperty("--f7-page-master-width", "418px");
      root.style.setProperty("--f7-panel-shadow", "transparent");
      root.style.setProperty("--f7-panel-width", "418px");
      return;
    }
    root.style.setProperty("--f7-panel-width", "100%");
  },
  methods: {
    updateList() {
      if (this.$keycloak.authenticated && !this.users) {
        this.$store.dispatch("account/getAllUsers");
      }
      f7.$("#userRecipientlist").hide();

      f7.$(".searchbar-hide-on-search").hide();
      if (this.users && this.users.length > 0) {
        this.getUserList();
      }
    },
    getUserList() {
      const self = this;

      self.autocompleteDropdownAjaxTypeahead = f7.autocomplete.create({
        inputEl: '#searchbar-autocomplete input[type="search"]',
        openIn: "dropdown",
        preloader: true, // enable preloader
        preloaderColor: "multi",
        valueProperty: "name",
        textProperty: "name",
        limit: 20, // limit to 20 results
        typeahead: true,
        notFoundText: self.$t("common.nothingFound"),
        dropdownContainerEl: "#autocompleteList",
        renderItem(item) {
          if (item.placeholder) {
            return `<li class=" item-content panel-close" data-panel=".sendMessageLeftPanel">
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">${item.text}</div>
                          </div>
                        </div>
                    </li>`;
          }
          for (const user of self.users) {
            if (user["name"] === item["value"]) {
              f7.utils.extend(item, user);
              break;
            }
          }
          const initials = item.name
            ? (item.name.split(" ")[0] || " ").charAt(0) +
              "" +
              (item.name.split(" ")[1] || " ").charAt(0)
            : "";
          if (item.logo === null) {
            var pict = `<span v-else color="auto" class="badge" slot="media" style="height: 48px; width: 48px; font-size: 16px; border-radius: 25px; background-color:var(--f7-theme-color) ">${initials}</span>`;
          } else {
            var pict = `<img src="data:image/png;base64,${item.logo}" class="userImage" v-if="${item.logo}" width="40"    style="height: 48px; width: 48px; border-radius: 25px" slot="media"/>`;
          }

          return `<div class="list media-list   panel-close" data-panel=".sendMessageLeftPanel">
                        <ul class="padding-left no-margin-left">
                          <li class="no-margin-left no-padding-left">
                          <label class="item-radio item-content  no-margin-left no-padding-left" data-value="${
                            item.value
                          }"   @click="selectUser(${item})">
                              <div class="item-media">${pict}</div>
                              <div class="item-inner">
                                  <div class="item-title">${item.text}</div>
                                <div class="item-footer avocash">${
                                  item.tag || " "
                                }</div>
                              </div>
                            </label>
                          </li>
                        </ul>
                      </div>`;
        },
        source(query, render) {
          const autocomplete = this;
          const results = [];
          if (query.length === 0) {
            render(results);
            return;
          }
          autocomplete.preloaderShow();
          for (const user of self.users) {
            if (
              user.name.toLowerCase().includes(query.toLowerCase()) ||
              user.phoneNumber.includes(query.toLowerCase()) ||
              user.email
                .substring(0, user.email.indexOf("@"))
                .toLowerCase()
                .includes(query.toLowerCase()) ||
              user.username.toLowerCase().includes(query.toLowerCase()) ||
              user.tag.toLowerCase().includes(query.toLowerCase()) ||
              user.accountNumber.includes(query.toLowerCase())
            )
              results.push(user);
          }
          console.log(results);
          // Hide Preoloader
          autocomplete.preloaderHide();
          // Render items by passing array with result items
          render(results);
        },
        on: {
          change(value) {
            console.log(value);
            f7.preloader.show("multi");
            self.$store.dispatch("account/setUser", value[0]);
            self.$store.dispatch("message/getCounterPartUser", value[0].userId);
            if (self.messageList.length >= 1) {
              self.$store.dispatch("message/getUserGroupMessages", "");
              self.$store.state.message.messageList = [];
              self.$store.state.message.messagesData = [];
            }
            setTimeout(() => {
              f7.views.main.router.navigate(
                `/message/${value[0].userId}/${value[0].groupType}`,
                {
                  reloadDetail: true,
                }
              );
              f7.preloader.hide();
              f7.panel.close(".sendMessageLeftPanel");
            }, 2000);
          },
        },
      });
    },

    selectUser(user) {
      const userIndexed = _.find(
        this.chatUsers,
        (userx) => userx.id === user.id
      );
      if (!userIndexed) {
        const currentUser = { userId: user.userId };
        this.chatUsers.push(user);
        this.chatUsersToPost.push(currentUser);
        return;
      }
      return false;
    },
    selectChatUser(user) {
      const userIndexed = _.find(
        this.chatUsers,
        (userx) => userx.id === user.id
      );
      if (userIndexed) {
        return false;
      }
      const currentUser = { userId: user.userId };

      this.chatUsers.push(user);
      this.chatUsersToPost.push(currentUser);
    },
    deleteChip(chatUser) {
      const index = this.chatUsers.indexOf(chatUser);
      this.chatUsers.splice(index, 1)[0];
      this.chatUsersToPost.splice(index, 1)[0];
    },
    clearSearch() {
      this.selectedGroup === "";
      f7.$(".searchbar-hide-on-search").show();
      f7.$("#userRecipientlist").show();
    },

    getPicturesAvatar() {
      this.fileInput = "chatAvatarInput";
      this.avatar = true;
      this.getFiles();
    },
  },
};

export const messageGroupMixin = {
  methods: {
    addCurrentParticipants() {
      for (const participant of this.chatGroupParticipantList) {
        if (this.isTablet == false) {
          f7.$(`.newGroupParticipant${participant.userId}`).css({
            "background-color": "#87bfff",
            disabled: true,
          });
        }
        if (this.isTablet == true) {
          f7.$(`input[name=participant${participant.userId}]`).prop({
            checked: true,
            disabled: true,
          });
        }
      }
    },

    selectParticipant(user) {
      const userIndexed = this.newGroupParticipants.findIndex(
        (x) => x.userId === user.userId
      );
      const currentparticipantIndex = this.chatGroupParticipantList.findIndex(
        (x) => x.userId === user.userId
      );
      console.log(userIndexed);
      if (userIndexed >= 0 && currentparticipantIndex < 0) {
        if (this.isTablet == false) {
          f7.$(`.newGroupParticipant${user.userId}`).css({
            "background-color": "transparent",
          });
        }
        if (this.isTablet == true) {
          f7.$(`input[name=participant${user.userId}]`).prop("checked", false);
        }
        this.newGroupParticipants.splice(userIndexed, 1);
        this.deleteRecipientChip(user);
        return;
      }
      if (!(userIndexed < 0 && currentparticipantIndex < 0)) {
        return;
      }
      this.newGroupParticipants.push(user);
      const newParticipant = {
        userId: user.userId,
      };
      this.participantsToAdd.push(newParticipant);
      console.log(this.participantsToAdd);
      if (this.isTablet == false) {
        f7.$(`.newGroupParticipant${user.userId}`).css({
          "background-color": "#87bfff",
        });
      }
      if (this.isTablet == true) {
        f7.$(`input[name=participant${user.userId}]`).prop("checked", true);
      }
    },

    deleteParticioantChip(grpParticipant) {
      const index = this.newGroupParticipants.indexOf(grpParticipant);
      const index2 = this.participantsToAdd.findIndex(
        (x) => x.userId === grpParticipant.userId
      );
      if (
        !this.chatGroupParticipantList.find(
          (x) => x.userId !== grpParticipant.userId
        )
      ) {
        return;
      }
      this.newGroupParticipants.splice(index, 1);
      this.participantsToAdd.splice(index2, 1);
      if (this.isTablet == false) {
        f7.$(`.newGroupParticipant${grpParticipant.userId}`).css({
          "background-color": "transparent",
        });
      }
      if (this.isTablet == true) {
        f7.$(`input[name=participant${grpParticipant.userId}]`).prop(
          "checked",
          false
        );
      }
    },

    addNewParticipants() {
      console.log(this.participantsToAdd);
      if (this.participantsToAdd && this.participantsToAdd.length > 0) {
        this.$store
          .dispatch("message/addGroupParticipant", {
            chatUsers: this.participantsToAdd,
            groupId: this.group.id,
          })
          .then(() => {
            for (const participant of this.newGroupParticipants) {
              this.deleteParticioantChip(participant);
            }
            for (const participant of this.chatGroupParticipantList) {
              if (this.isTablet == false) {
                f7.$(`.newGroupParticipant${participant.userId}`).css({
                  "background-color": "transparent",
                  disabled: false,
                });
              }
              if (this.isTablet == true) {
                f7.$(`input[name=participant${participant.userId}]`).prop({
                  checked: false,
                  disabled: false,
                });
              }
            }
            f7.panel.close(".addParticipantGroupRightPanel", true);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    removeParticipant(participant) {
      f7.dialog.confirm(
        this.$t("tamtammessage.areYouSureToRemoveParticipant", {
          participantToDelete: `${participant.name}`,
        }),
        () => {
          this.$store.dispatch("message/removeGroupParticipant", {
            userId: participant.userId,
            groupId: this.group.id,
          });
        }
      );
    },
    grantAdminRole(participant) {
      f7.dialog.confirm(
        this.$t("tamtammessage.areYouSureToGiveAdminright", {
          participantToDelete: `${participant.name}`,
        }),
        () => {
          this.$store.dispatch("message/grantGroupAdminRights", {
            userId: participant.userId,
            groupId: this.group.id,
          });
        }
      );
    },
    withdrawAdminRole(participant) {
      f7.dialog.confirm(
        this.$t("tamtammessage.areYouSureToRemoveAdminright", {
          participantToDelete: `${participant.name}`,
        }),
        () => {
          this.$store.dispatch("message/removeGroupAdminRights", {
            userId: participant.userId,
            groupId: this.group.id,
          });
        }
      );
    },
    leaveGroup(group) {
      f7.dialog.confirm(
        this.$t("tamtammessage.areYouSureToExitGroup", {
          theGroup: `${group.name}`,
        }),
        () => {
          this.$store.dispatch("message/leaveGroup", {
            groupId: group.id,
          });
        }
      );
    },
    deleteGroup(group) {
      f7.dialog.confirm(
        this.$t("tamtammessage.areYouSureToDeletetGroup", {
          theGroup: `${group.name}`,
        }),
        () => {
          this.$store.dispatch("message/deleteGroup", {
            groupId: group.id,
          });
        }
      );
    },

    deleteChat(group) {
      f7.dialog.confirm(
        this.$t("tamtammessage.areYouSureToDeletetChat", {
          theGroup: `${group.name}`,
        }),
        () => {
          this.$store.dispatch("message/deleteGroup", {
            groupId: group.id,
          });
        }
      );
    },

    editGroupName() {
      this.groupNameDisabled = false;
      f7.input.focus("#nameInput");
    },
    saveGroupName() {
      this.$store.dispatch("message/updateGroupInfo", {
        changeType: "groupName",
        change: this.groupName,
        groupId: this.group.id,
      });
      this.groupNameDisabled = true;
      f7.input.blur("#nameInput");
    },
    editDescription() {
      this.descDisabled = false;
      f7.input.focus("#descInput");
    },
    saveDesc() {
      console.log(this.description);
      this.$store.dispatch("message/updateGroupInfo", {
        changeType: "description",
        change: this.description,
        groupId: this.group.id,
      });
      this.descDisabled = true;
      f7.input.blur("#descInput");
    },
    getPicturesAvatar() {
      this.fileInput = "chatAvatarInput";
      this.avatar = true;
      this.getFiles();
    },
    saveAvatar() {
      const result = this.$refs.cropper.getResult();
      if (result) {
        result.canvas.toBlob((blob) => {
          this.forms = new FormData();
          this.forms.append("file", blob, "avatar");
          this.forms.append("groupId", this.group.id);
          this.$store.dispatch("message/updateGroupAvatar", {
            forms: this.forms,
          });
        });
        return;
      }
      this.forms = new FormData();
      for (const file of this.files) {
        this.forms.append("file", file, "avatar");
      }
      this.forms.append("groupId", this.group.id);
      this.$store.dispatch("message/updateGroupAvatar", {
        forms: this.forms,
      });
    },
    blockUser(user) {
      f7.dialog.confirm(
        this.$t("tamtammessage.areYouSureToBlock", {
          theParticipant: `${user.name}`,
        }),
        () => {
          this.$store.dispatch("message/blockUser", user.id);
        }
      );
    },
    unBlockUser(user) {
      this.$store.dispatch("message/unBlockUser", user.id);
    },

    markUnread(currentGroupDetail) {
      console.log(currentGroupDetail);
    },
    addSubgroup(currentGroupDetail) {
      this.$store.dispatch(
        "message/getGroupParticipantList",
        currentGroupDetail.id
      );
      this.selectedGroup = currentGroupDetail;
      f7.panel.open(".createGroupeLeftPanel");
    },
  },
  computed: {
    ...mapState("message", [
      "chatUser",
      "message",
      "chatUserList",
      "messageList",
      "group",
      "groupList",
      "subGroup",
      "subGroupList",
      "blockedUser",
      "blockedUserList",
      "blockedUserMsg",
      "groupAdmin",
      "groupAdminList",
      "counterPartUser",
      "counterPartUserList",
      "groupParticipantList",
      "messageIsSelected",
      "selectedMessages",
    ]),
    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "locale",
      "documentsUrl",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
    ]),
  },
};
