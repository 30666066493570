import axios from "axios";
import daysjs from "../../day.js";
const path = import.meta.env.VITE_APP_URL + "message";
import _ from "lodash";

function getDateDay(value) {
  let date;
  if (value === "") return;
  if (typeof value === "string") {
    date = new Date(Date.parse(value));
  } else if (typeof parseInt(value) === "number") {
    date = new Date(value);
  }
  return daysjs(date).fromNow();
}
//  const message={
export default (app) => {
  return {
    namespaced: true,
    state: {
      message: "",
      chatUser: "",
      chatUserList: "",
      messageList: "",
      group: "",
      groupList: "",
      subGroup: "",
      subGroupList: "",
      // Vendor Qr code creation and payment
      blockedUser: "",
      blockedUserList: "",
      blockedUserMsg: "",
      groupAdmin: "",
      groupAdminList: "",
      counterPartUser: "",
      counterPartUserList: "",
      groupParticipantList: "",
      infoMessage: "",
      messagesData: "",
      groupUnReadMsg: "",
      allGroupsUnReadMsg: [],
      replyMessage: "",
      messageIsSelected: false,
      selectedMessages: [],
      // attachments: [],
      msgCltConnected: false,
      messageSocketClient: "",
      typingMessage: "",
    },
    getters: {
      // messagesData: state => {
      //     return _.chain(state.messageList)
      //         .orderBy(["sentAt"], ["asc"])
      //         .map((messagea) =>
      //             _.extend({
      //                     messageDate: messagea.sentAt.substr(0, 10),
      //                 },
      //                 messagea
      //             )
      //         )
      //         .groupBy("messageDate")
      //         .value();
      // },
    },
    mutations: {
      JOIN(state, chatUser) {
        state.chatUser = chatUser;
      },
      GET_GROUP(state, group) {
        state.group = group;
      },
      GET_SUBGROUP(state, subGroup) {
        state.subGroup = subGroup;
      },

      GET_GROUP_LIST(state, groupList) {
        state.groupList = groupList;
      },
      GET_GROUP_MESSAGES(state, messageList) {
        state.messageList = messageList;
        state.messagesData = _.chain(state.messageList)
          .orderBy(["sentAt"], ["asc"])
          .map((messagea) =>
            _.extend(
              {
                /*`data:image/png;base64,*/
                messageDate: getDateDay(messagea.sentAt),
                // messageDate: typeof messagea.sentAt === 'string' ? messagea.sentAt.substr(0, 10) : (new Date(messagea.sentAt)).getFullYear() + '-' + ("0" + (new Date(messagea.sentAt).getMonth() + 1)).substr(-2) + '-' + ("0" + (new Date(messagea.sentAt).getDate() + 1)).substr(-2),
                url: `data:${messagea.mimetype};base64,${messagea.attachment}`,
              },
              messagea
            )
          )
          .groupBy("messageDate")
          .value();
        console.log(state.messagesData);
      },
      BLOCKED_USER_MSG(state, blockedUserMsg) {
        state.blockedUserMsg = blockedUserMsg;
      },
      GET_COUNTERPART_USER(state, counterPartUser) {
        state.counterPartUser = counterPartUser;
      },
      SET_MSG(state, message) {
        state.message = message;
        const msgIndex = _.findIndex(
          state.messageList,
          (msg) => msg.id === message.id
        );
        if (msgIndex < 0) {
          state.messageList.push(message);
          state.messagesData = _.chain(state.messageList)
            .orderBy(["sentAt"], ["asc"])
            .map((messagea) =>
              _.extend(
                {
                  messageDate: getDateDay(messagea.sentAt),
                  // messageDate: typeof messagea.sentAt === 'string' ? messagea.sentAt.substr(0, 10) : (new Date(messagea.sentAt)).getFullYear() + '-' + ("0" + (new Date(messagea.sentAt).getMonth() + 1)).substr(-2) + '-' + ("0" + (new Date(messagea.sentAt).getDate() + 1)).substr(-2),
                  url: `data:${messagea.mimetype};base64,${messagea.attachment}`,
                },
                messagea
              )
            )
            .groupBy("messageDate")
            .value();
        }
      },
      SET_INFO_MSG(state, message) {
        state.infoMessage.push(message);
      },
      SET_REPLY_MSG(state, replyMessage) {
        state.replyMessage = replyMessage;
      },
      SET_GP_UNREAD_MSG_COUNT(state, groupUnReadMsg) {
        const groupInUnreadInGroups = _.find(
          state.allGroupsUnReadMsg,
          (group) => group.id === groupUnReadMsg.id
        );
        if (groupInUnreadInGroups) {
          groupInUnreadInGroups.count += groupUnReadMsg.count;
        } else {
          state.allGroupsUnReadMsg.push(groupUnReadMsg);
        }
      },
      GET_COUNTERPART_USER_LIST(state, counterPartUserList) {
        state.counterPartUserList = counterPartUserList;
      },

      GET_GROUP_PARTICIPANT_LIST(state, groupParticipantList) {
        state.groupParticipantList = groupParticipantList;
      },

      SET_MSGSELECTED(state, messageIsSelected) {
        state.messageIsSelected = messageIsSelected;
      },
      SELECT_MSG(state, selectedMessage) {
        state.selectedMessages.push(selectedMessage);
      },
      DE_SELECT_MSG(state, selectedMessage) {
        const msgIndex = state.selectedMessages.findIndex(
          (x) => x.id === selectedMessage.id
        );
        state.selectedMessages.splice(msgIndex, 1);
      },
      MSG_CONNECT_SOCKET(state, msgCltConnected) {
        state.msgCltConnected = msgCltConnected;
      },

      MSG_CONNECT_SOCKET_CLIENT(state, messageSocketClient) {
        state.messageSocketClient = messageSocketClient;
      },
      SET_TYPING_MSG(state, typingMessage) {
        state.typingMessage = typingMessage;
      },
    },

    actions: {
      setInfoMsg({ commit }, message) {
        commit("SET_INFO_MSG", message);
      },
      setMsg({ commit }, message) {
        commit("SET_MSG", message);
      },
      setCounterPartUSerOnlineStatus({ commit }, counterPartUser) {
        commit("GET_COUNTERPART_USER", counterPartUser);
      },
      setUnReadMsgCount({ commit }, groupUunReadMsg) {
        commit("SET_GP_UNREAD_MSG_COUNT", groupUunReadMsg);
      },

      setReplyMsg({ commit }, message) {
        commit("SET_REPLY_MSG", message);
      },

      messageIsSelected({ commit }, messageIsSelected) {
        commit("SET_MSGSELECTED", messageIsSelected);
      },
      selectMessage({ commit }, payload) {
        console.log(payload);
        if (payload.action === "add") {
          commit("SELECT_MSG", payload.msg);
        } else {
          commit("DE_SELECT_MSG", payload.msg);
        }
      },
      msgConnectSocketed({ commit }, msgCltConnected) {
        commit("MSG_CONNECT_SOCKET", msgCltConnected);
        console.log("msgConnectSocketed", msgCltConnected);
      },

      msgConnectSocketClient({ commit }, messageSocketClient) {
        commit("MSG_CONNECT_SOCKET_CLIENT", messageSocketClient);
        console.log("msgConnectSocketedclient", messageSocketClient);
      },
      setTypingMessage({ commit }, payload) {
        commit("SET_TYPING_MSG", payload);
      },

      getChatUser({ commit }) {
        axios({
          url: `${path}/chatUser`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((chatUser) => {
            commit("JOIN", chatUser);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      joinMessaging({ commit }, payload) {
        axios({
          url: `${path}/join`,
          method: "POST",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((chatUser) => {
            commit("JOIN", chatUser);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      createGroup({ commit }, payload) {
        axios({
          url: `${path}/group/create`,
          method: "POST",
          data: payload.forms,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      // TODO make sure the chatGroupId is added to the entity otherwise creates new chat group on click
      createAssoTontineWalletShopDeliveryAcctGroup({ commit }, payload) {
        axios({
          url: path + payload.url,
          method: "POST",
          data: payload.data,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getAssociatedGroup({ commit, dispatch }, payload) {
        axios({
          url: `${path}/associatedGroup/detail`,
          method: "GET",
          params: {
            requestId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
            console.log(group);
            if (group.groupType === "PERSONAL") {
              dispatch("groupCounterPartUser", group.id);
            }
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      updateGroupInfo({ commit }, payload) {
        axios({
          url: `${path}/group/update`,
          method: "PUT",
          params: {
            groupId: payload.groupId,
            changeType: payload.changeType,
            change: payload.change,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      updateGroupAvatar({ commit }, payload) {
        axios({
          url: `${path}/group/update/avatar`,
          method: "POST",
          data: payload.forms,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      sendFileMessage({ commit }, payload) {
        axios({
          url: `${path}/send/fileMessage`,
          method: "POST",
          data: payload.forms,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getGroupDetail({ commit, dispatch }, payload) {
        axios({
          url: `${path}/group/detail`,
          method: "GET",
          params: {
            requestId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
            console.log(group);
            if (group.groupType === "PERSONAL") {
              dispatch("groupCounterPartUser", group.id);
            }
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getUserGroups({ commit }) {
        axios({
          url: `${path}/user/groups`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupList) => {
            commit("GET_GROUP_LIST", groupList);
            console.log(groupList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getUserGroupMessages({ commit }, payload) {
        axios({
          url: `${path}/chat/messages`,
          method: "GET",
          params: {
            requestId: payload.requestId,
            displayedMsg: payload.displayedMsg,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((messageList) => {
            commit("GET_GROUP_MESSAGES", messageList);
            console.log(messageList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      groupCounterPartUser({ commit }, payload) {
        axios({
          url: `${path}/groupCounterPartUser`,
          method: "GET",
          params: {
            requestId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((counterPartUser) => {
            commit("GET_COUNTERPART_USER", counterPartUser);
            console.log(counterPartUser);
          })
          .catch((err) => {
            console.log(err.response.data);
            // commit("SET_INFO_MSG", err.response.data);
          });
      },
      getCounterPartUser({ commit }, payload) {
        axios({
          url: `${path}/counterPartUser`,
          method: "GET",
          params: {
            requestId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((counterPartUser) => {
            commit("GET_COUNTERPART_USER", counterPartUser);
            console.log(counterPartUser);
          })
          .catch((err) => {
            console.log(err.response.data);
            // commit("SET_INFO_MSG", err.response.data);
          });
      },
      getCounterPartUserList({ commit }) {
        axios({
          url: `${path}/counterPartUserList`,
          method: "GET",

          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((counterPartUserList) => {
            commit("GET_COUNTERPART_USER_LIST", counterPartUserList);
            console.log(counterPartUserList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getGroupParticipantList({ commit }, payload) {
        axios({
          url: `${path}/group/participantList`,
          method: "GET",
          params: {
            requestId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupParticipantList) => {
            commit("GET_GROUP_PARTICIPANT_LIST", groupParticipantList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      deleteMessage({ commit }, payload) {
        axios({
          url: `${path}/delete/message`,
          method: "PUT",
          params: {
            messageId: payload.messageId,
            forAll: payload.forAll,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((messageList) => {
            commit("GET_GROUP_MESSAGES", messageList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      inviteSingleUser({ commit }) {
        axios({
          url: `${path}/invite`,
          method: "POST",
          params: {
            userId: app.config.globalProperties.$keycloak.subject,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      inviteAcceptSingleUser({ commit }, payload) {
        axios({
          url: `${path}/invite/response`,
          method: "POST",
          params: {
            groupId: payload.groupId,
            activate: payload.activate,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      addGroupParticipant({ commit }, payload) {
        console.log(payload);
        axios({
          url: `${path}/group/add/participant`,
          method: "POST",
          data: payload.chatUsers,
          params: {
            groupId: payload.groupId,
            // userId: payload.userId
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      removeGroupParticipant({ commit }, payload) {
        axios({
          url: `${path}/group/remove/participant`,
          method: "PUT",
          params: {
            groupId: payload.groupId,
            userId: payload.userId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      deleteGroup({ commit }, payload) {
        axios({
          url: `${path}/group/delete`,
          method: "PUT",
          params: {
            groupId: payload.groupId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupList) => {
            commit("GET_GROUP_LIST", groupList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      leaveGroup({ commit }, payload) {
        axios({
          url: `${path}/group/leave`,
          method: "PUT",
          params: {
            groupId: payload.groupId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupList) => {
            commit("GET_GROUP_LIST", groupList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      blockUser({ commit }, payload) {
        axios({
          url: `${path}/user/block`,
          method: "PUT",
          params: {
            chatUserId: payload.chatUserId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((blockUserMshg) => {
            commit("BLOCKED_USER_MSG", blockUserMshg);
          })
          .catch((err) => {
            console.log(err.response.data);
            commit("BLOCKED_USER_MSG", err.response.data);
          });
      },
      unBlockUser({ commit }, payload) {
        axios({
          url: `${path}/user/unblock`,
          method: "PUT",
          params: {
            chatUserId: payload.chatUserId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((blockUserMshg) => {
            commit("BLOCKED_USER_MSG", blockUserMshg);
          })
          .catch((err) => {
            console.log(err.response.data);
            commit("BLOCKED_USER_MSG", err.response.data);
          });
      },

      grantGroupAdminRights({ commit }, payload) {
        axios({
          url: `${path}/group/add/adminRole`,
          method: "POST",
          params: {
            groupId: payload.groupId,
            userId: payload.userId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      removeGroupAdminRights({ commit }, payload) {
        axios({
          url: `${path}/group/remove/adminRole`,
          method: "PUT",
          params: {
            groupId: payload.groupId,
            userId: payload.userId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((group) => {
            commit("GET_GROUP", group);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      createSubGroup({ commit }, payload) {
        axios({
          url: `${path}/subGroup/create`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((subGroup) => {
            commit("GET_SUBGROUP", subGroup);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      deleteSubGroup({ commit }, payload) {
        axios({
          url: `${path}/subGroup/delete`,
          method: "PUT",
          params: {
            subGroupId: payload.subGroupId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((groupList) => {
            commit("GET_GROUP_LIST", groupList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      sendMessageToUsers(payload) {
        axios({
          url: `${path}/send`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        });
        // .then((r) => r.data)
        // .then((topic) => {
        //   commit("SEND_MESSAGE_TO_USERS", topic);
        // })
        // .catch((err) => {
        //   console.log(err.response.data);
        // });
      },
    },
  };
};
// export default message;
