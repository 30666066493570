<template>
  <f7-app v-bind="f7params">
    <f7-view :main="true" url="/" class="safe-areas large strong-ios" :master-detail-breakpoint="768"
      :master-detail-resizable="true">
      <!-- <f7-toolbar tabbar no-shadow :position="isTablet ? 'top' : 'bottom'" class="mainToolbar webToolbar" icons
        v-if="!device.cordova">
        <mainTabbar />
      </f7-toolbar> -->
    </f7-view>
    <f7-popup v-if="this.onboardingFinished" class="startPopup" tablet-fullscreen :backdrop="false" close-on-escape
      :animate="false">
      <f7-page>
        <f7-navbar>
          <f7-nav-title>
            <img src="/32x32.png" alt="avocash logo" width="32" height="32" style="vertical-align: middle" />
            <span class="avocash text-color-primary padding-left-half" style="
                letter-spacing: var(--f7-tabbar-label-letter-spacing);
                font-weight: bold;
                font-size: large;
              ">avo¢ash</span>
          </f7-nav-title>
        </f7-navbar>
      </f7-page>
    </f7-popup>

    <onboardingPopup></onboardingPopup>
    <html5QRScan></html5QRScan>
    <editDocs></editDocs>
    <attachDoc></attachDoc>
    <addVideo></addVideo>
    <navbarright></navbarright>
    <pwaInstall></pwaInstall>
    <createGroupPanel></createGroupPanel>
  </f7-app>
</template>
<style scoped></style>
<script>
import mainTabbar from "../components/mainTabbar.vue";

import Navbarright from "./navbarright.vue";
import template_preloader from "./template_preloader.vue";
import onboardingPopup from "./onboardingPopup.vue";
import html5QRScan from "./html5QRScan.vue";
import editDocs from "./editDocs.vue";
import attachDoc from "./attachDocs.vue";
import pwaInstall from "./pwa-install.vue";
import addVideo from "./addVideo.vue";
import Navbarleft from "./navbarleft.vue";
import panelright from "../pages/panel-right.vue";
import bankCard from "../pages/bankCard.vue";
import createGroupPanel from "./tamtam/createGpPanel.vue";
import "intl-tel-input/build/css/intlTelInput.min.css";
import "intl-tel-input/build/js/intlTelInput.js";

import pwa from "../js/pwa.js";

import { messageSocket } from "../js/websocket/websocketConnect.js";
// import { messageConnect } from "../js/websocket/messageWebsocket.js";
import { scanQRCodeGeneral } from "../js/mixins/scanPayQRCode.js";
import { cordovaPlugin, socialShare } from "../js/mixins/cordovaPlugins.js";
import { firebaseFn } from "../js/mixins/firebasex.js";
// import { ref, onMounted } from "vue";
import { f7, f7ready, theme } from "framework7-vue";
import { getDevice } from "framework7/lite-bundle";
import cordovaApp from "../js/cordova-app.js";
import { mapGetters, mapState } from "vuex";
import routes from "../js/routes.js";
import store from "../js/oauth2.js";
import { $ } from "dom7";

import { loginFn } from "../js/mixins/loginFn.js";
// import { transactAuth } from "../js/mixins/transactAuth.js";
const device = getDevice();
import _ from "lodash";
const countryData = window.intlTelInputGlobals.getCountryData();
export default {
  mixins: [
    cordovaPlugin,
    messageSocket,
    // messageConnect,
    scanQRCodeGeneral,
    socialShare,
    pwa,
    firebaseFn,
    loginFn,
    // transactAuth,
  ],
  components: {
    navbarright: Navbarright,
    templatePreloader: template_preloader,
    navbarleft: Navbarleft,
    onboardingPopup,
    html5QRScan,
    mainTabbar,
    editDocs,
    addVideo,
    pwaInstall,
    panelright,
    attachDoc,
    createGroupPanel,
    bankCard,
  },
  data() {
    return {
      theme,
      f7,
      // Framework7 Parameters
      f7params: {
        id: "com.avocash.pay",
        name: "avo¢ash", // App name
        theme: "auto", // Automatic theme detection
        version: "1.1.0",
        language: navigator.language.split("-")[0],
        colors: {
          // specify primary color theme
          primary: "#0009ff",
        },
        // App store
        store,
        // App routes
        routes,
        // Register service worker (only on production build)
        serviceWorker:
          process.env.NODE_ENV === "production"
            ? {
              path: "/service-worker.js",
            }
            : {
              path: "/service-worker.js",
            },

        view: {
          browserHistory: !device.cordova /* && !device.standalone && !device.webview && !device.webView*/,
          browserHistoryRoot: `${window.location.origin}`,
          browserHistorySeparator: "",
          browserHistoryInitialMatch: true,
          masterDetailResizable: true,
          masterDetailBreakpoint: 768,
          mdSwipeBack: true,
          reloadDetail: true,
          transition: "f7-parallax",
          iosDynamicNavbar: true,
        },
        // darkMode: "auto",
        // Input settings
        input: {
          scrollIntoViewOnFocus: device.cordova,
          scrollIntoViewCentered: device.cordova,
        },
        // Cordova Statusbar settings
        statusbar: {
          iosOverlaysWebView: true,
          // androidOverlaysWebView: false,
          androidBackgroundColor: "#FFFFFFFF", //#EFECFF
          androidTextColor: "black",
          iosBackgroundColor: "#FFFFFFFF", //#EFECFF
          // iosTextColor: "black",
        },

        navbar: {
          // hideOnPageScroll: true,
          iosCenterTitle: true,
          mdCenterTitle: this.isTablet ? true : false,
          backLinkShowText: false,
        },
        actions: {
          convertToPopover: true,
          closeOnEscape: true,
          closeByOutsideClick: true,
          // grid: true
        },
        popup: {
          closeOnEscape: true,
          tabletFullscreen: true,
          popupCloseLinkText: this.$t("common.close"),
          animate: true,
          // push: true,
          backdrop: false,
        },
        sheet: {
          closeOnEscape: true,
          // push: true,
          backdrop: false,
        },
        popover: {
          closeOnEscape: true,
          backdrop: false,
        },
        calendar: {
          toolbarCloseText: this.$t("common.done"),
          headerPlaceholder: this.$t("common.selectDate"),
          openIn: "auto",
          // sheetPush: true,
        },
        dialog: {
          title:
            "<div class='box' style='align-items:justify;'><img src='/32x32.png' alt='avocash icon' width='32' height='32' style='margin-right:8px; vertical-align: middle;' class='elevation-3'/><span class='avocash color-primary' style='text-transform:lowercase; color: var(--f7-theme-color); font-weight: bold'>avo¢ash</span></div>",
          buttonOk: this.$t("common.buttonOk"),
          buttonCancel: this.$t("common.cancel"),
          // backdrop: false,
        },
        notification: {
          icon:
            '<i class="icon elevation-3"><img src="/32x32.png" width="32" height="32" alt="avocash icon"></i>',
          title:
            "<span class='avocash color-primary' style='text-transform:lowercase; color: var(--f7-theme-color); font-weight: bold'>avo¢ash</span>",
        },
        touch: {
          tapHold: true,
          tapHoldDelay: 750,
          touchClicksDistanceThreshold: 5,
          activeState: true,
        },
        clicks: {
          externalLinks: ".external",
        },

        photoBrowser: {
          "page-back-link-text": "",
          "popup-close-link-text": '<i class="icon f7-icons">xmark_circle</i>', //this.$t("common.close"),
          navbarOfText: "/",
          type: "standalone",
          // popupPush: true,
        },
        smartSelect: {
          searchbarDisableText: this.$t("common.cancel"),
          popupCloseLinkText: '<i class="icon f7-icons">xmark_circle</i>', // this.$t("common.close"),
          pageBackLinkText: "",
          popupTabletFullscreen: true,
          sheetCloseLinkText: this.$t("common.done"),
          searchbarPlaceholder: this.$t("common.search"),
          // sheetPush: true,
          // popupPush: true,
        },
        searchbar: {
          disableButtonText: this.$t("common.cancel"),
          placeholder: this.$t("common.search"),
        },
        textEditor: {
          mode: "keyboard-toolbar",
          imageUrlText: this.$t("common.imageUrlText"),
          linkUrlText: this.$t("common.linkUrlText"),
        },
        swiper: {
          keyboard: {
            enabled: true,
          },
          mousewheel: true,
        },
      },
      currDevice: "",
      redirect: "",
      isReady: false,
    };
  },

  methods: {
    navigateHome() {
      if (this.isTablet) {
        f7.views.main.router.navigate("/stores/");
        f7.views.main.router.navigate("/");
      }
    },
    convertToDeskTop() {
      if (!device.cordova && f7.width > 568) {
        this.$store.dispatch("auth/isDeskTop", true); //No cordova and large screen
        console.log(f7);
        console.log(this.isDeskTop);
      }
      if (f7.width > 568) {
        this.$store.dispatch("auth/isTablet", true);
      } else {
        this.$store.dispatch("auth/isTablet", false);
      }
      if (device.cordova) {
        this.$store.dispatch("auth/isCordova", true);
      }
    },
    findCountryInCountrydata(countryCode) {
      for (const countryDaton of countryData) {
        if (countryDaton.iso2 === countryCode.toLowerCase()) {
          return countryDaton;
        }
      }
    },
    redirectFunction() {
      let link;
      if (process.env.NODE_ENV === "production") {
        console.log("window.location.href", window.location.href);
        if (
          (device.standalone || device.webView || device.webview) &&
          window.location.href.search("/#") >= 0
        ) {
          console.log("window.location.href", window.location.href);
          link = window.location.href.split("/#").pop().split("?")[0];
        } else {
          link = window.location.href.split(".com").pop().split("?")[0];
          console.log("link", link);
        }
      } else {
        console.log("window.location.href", window.location.href);
        link = window.location.href.split(":5173").pop().split("?")[0];
      }
      if (link !== "" && link != undefined && link !== "/") {
        f7.preloader.show("multi");
        f7.views.main.router.navigate(link);
        f7.preloader.hide();
      }
    },
  },
  mounted() {
    this.currDevice = device;
    this.isReady = true;
    if (device.ios) {
      $(".item-input").removeClass("item-input-outline");
    } else {
      $(".item-input").removeClass("item-input-outline");
    }

    f7ready(() => {
      // Init cordova APIs (see cordova-f7.js)

      if (device.cordova) {
        cordovaApp.init(f7);
      } else {
        this.redirectOnGuardedRoute();
        // this.redirectFunction();
      }
      // f7.statusbar.setBackgroundColor("#fff");
      // f7.statusbar.setTextColor("black");
      console.log("device", device);
      console.log("window.location", window.location);
      this.$store.dispatch("auth/isCordova", device.cordova);

      this.convertToDeskTop();
      // this.$store.dispatch("auth/setDevice", f7.device);

      const root = document.documentElement;
      root.style.setProperty("--f7-panel-shadow", "transparent");
      if (f7.device.ios && f7.$(".toolbar.tabbar.toolbar-top.mainToolbar")) {
        f7.$(".view-main div.navbars").css({
          display: "none",
        });
      }

      if (!this.msgCltConnected) {
        if (this.csrfToken === "") {
          this.$store.dispatch("auth/getCsrfToken");
        }
        setTimeout(() => {
          this.connectMessageSocket();
        }, 6000);
      }
      if (!this.projectCategories || this.projectCategories === "") {
        this.$store.dispatch("wallet/getWalletCategory");
      }
      this.$store.dispatch("wallet/getPublicWalletList", 6);
      console.log(f7.device);
      console.log(app);

      if (
        (f7.utils.now() - window.localStorage.getItem("cartDuration")) / 60000 > 30 &&
        !this.authenticated
      ) {
        this.$store.dispatch("shoppingCart/deleteCart");
      }
      if (this.categories === "") {
        this.$store.dispatch("shop/getCategory");
      }
      if (this.optionNames === "") {
        this.$store.dispatch("product/getOptionNames");
      }
      this.$store.dispatch("location/getCurrentUserIpAddress");

      if (this.country === "" || (this.country && !this.country.length)) {
        this.$store.dispatch("location/getCountry");
      }
      if (this.currencyList === "" || (this.currencyList && !this.currencyList.length)) {
        this.$store.dispatch("location/getCurrencyList");
      }
      if (
        this.emoneyApiList === "" ||
        (this.emoneyApiList && !this.emoneyApiList.length)
      ) {
        this.$store.dispatch("location/getEmoneyApiList");
      }
      if (this.airtimeCountries) {
        this.$store.dispatch("airtime/getCountries");
      }
      if (this.airtimeCurrencies) {
        this.$store.dispatch("airtime/getCurrencies");
      }
      if (this.country && this.country.length > 0 && !this.country[0].name) {
        const countries = this.country;
        for (let i = 0; i < countries.length; i++) {
          if (countries !== null) {
            var newCOuntry = this.findCountryInCountrydata(countries[i].countryCode2);
            if (newCOuntry) {
              countries[i].name = this.findCountryInCountrydata(
                countries[i].countryCode2
              ).name;
              countries[i].iso2 = this.findCountryInCountrydata(
                countries[i].countryCode2
              ).iso2;
              countries[i].dialCode = this.findCountryInCountrydata(
                countries[i].countryCode2
              ).dialCode;
            } else {
              countries[i].name = countries[i].countryName;
              countries[i].iso2 = countries[i].countryCode2.toLowerCase;
              countries[i].dialCode = `+${countries[i].phoneCode}`;
            }
          }
        }
        this.$store.dispatch("location/setCountries", countries);
      }
      setTimeout(() => {
        if (this.authenticated == true) {
          this.$store.dispatch(
            "location/getCurrentUserCountry",
            this.profile.countryCode
          );
          this.$store.dispatch("account/createNewUservocashAccounts", this.profile);
          console.log("user profile", this.isAuthenticated);
        }

        if (this.authenticated && this.userAccount === null) {
          setInterval(() => {
            this.$store.dispatch("account/getUserAccount", this.profile);
          }, 6000);
        }
      }, 100);

      if (f7.views.main) {
        this.redirect = f7.device.cordova
          ? `${import.meta.env.VITE_AVOCASH_MOBILE_URL_SCHEME}${f7.views.main.router.currentRoute.path
          }`
          : `${window.location.origin}${f7.views.main.router.currentRoute.path}`;
        console.log(this.redirect);
      }
      this.$keycloak.onAuthLogout = () => {
        if (
          f7.views.main.router.currentRoute.route.beforeEnter &&
          f7.views.main.router.currentRoute.path !== "/"
        ) {
          f7.views.main.router.navigate("/");
        }
      };
    });
    window.addEventListener("resize", () => {
      this.convertToDeskTop();
    });
    if (!f7.device.cordova) {
      document.addEventListener(
        "backbutton",
        function (e) {
          if ($(".actions-modal.modal-in").length) {
            f7.actions.close(".actions-modal.modal-in");
            e.preventDefault();
            return false;
          }
          if ($(".dialog.modal-in").length) {
            f7.dialog.close(".dialog.modal-in");
            e.preventDefault();
            return false;
          }
          if ($(".sheet-modal.modal-in").length) {
            f7.sheet.close(".sheet-modal.modal-in");
            e.preventDefault();
            return false;
          }
          if ($(".popover.modal-in").length) {
            f7.popover.close(".popover.modal-in");
            e.preventDefault();
            return false;
          }
          if ($(".popup.modal-in").length) {
            if ($(".popup.modal-in>.view").length) {
              const currentView = f7.views.get(".popup.modal-in>.view");
              if (
                currentView &&
                currentView.router &&
                currentView.router.history.length > 1
              ) {
                currentView.router.back();
                e.preventDefault();
                return false;
              }
            }
            f7.popup.close(".popup.modal-in");
            e.preventDefault();
            return false;
          }
          if ($(".login-screen.modal-in").length) {
            f7.loginScreen.close(".login-screen.modal-in");
            e.preventDefault();
            return false;
          }

          if ($(".page-current .searchbar-enabled").length) {
            f7.searchbar.disable(".page-current .searchbar-enabled");
            e.preventDefault();
            return false;
          }

          if ($(".page-current .card-expandable.card-opened").length) {
            f7.card.close(".page-current .card-expandable.card-opened");
            e.preventDefault();
            return false;
          }

          const currentView = f7.views.current;
          if (
            currentView &&
            currentView.router &&
            currentView.router.history.length > 1
          ) {
            currentView.router.back();
            e.preventDefault();
            return false;
          }

          if ($(".panel.panel-in").length) {
            f7.panel.close(".panel.panel-in");
            e.preventDefault();
            return false;
          }
        },
        false
      );
    }
  },

  computed: {
    ...mapState("location", [
      "destinationCity",
      "country",
      "currencyList",
      "countryStates",
      "countryCities",
      "currentUserCountry",
      "userAddress",
      "emoneyApiList",
    ]),

    ...mapGetters("transaction", [
      "allCountriesByCountryId",
      "allCountriesByCountryName",
      "fromCurrencyRate",
      "toCurrencyRate",
      "grossexchangerate2",
      "destinationCountry",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry", "ipAddress"]),

    ...mapState("auth", [
      "profile",
      "token",
      "user",
      "authenticated",
      "topath",
      "phoneNumberDetail",
      "mobilePhoneError",
      "isDeskTop",
      "isTablet",
      "isCordova",
      "locale",
      "lastSwiperIsActive",
      "onboardingFinished",
      "dynamicLink",
      "csrfToken",
    ]),
    ...mapState("shoppingCart", ["cart", "cartItemCount"]),
    ...mapGetters("shoppingCart", ["totalCartAmount", "totalItemCount"]),
    ...mapState("account", ["userAccount", "standard", "business"]),
    ...mapState("wallet", [
      "wallet",
      "projectCategories",
      "walletOwner",
      "walletList",
      "publicWalletList",
      "walletTransaction",
      "thirdPartyAllowedWalletList",
      "privateWalletTransactionList",
      "publicWalletTransactionList",
      "walletTransactionList",
      "walletImages",
    ]),
    ...mapGetters("auth", ["getProfile", "isAuthenticated", "isProfileLoaded"]),
    ...mapState("message", [
      "groupList",
      "chatUser",
      "messageSocketClient",
      "msgCltConnected",
    ]),
  },
};
</script>
