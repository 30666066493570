<template>
  <f7-card outline header-divider>
    <f7-card-header class="">
      {{ $t("common.transactions") }}
    </f7-card-header>
    <div class="cols95_05 margin-bottom">
      <f7-searchbar
        class="searchbar-transactions"
        search-container=".transactions-list"
        :placeholder="$t('mytransactions.searchATransaction')"
        search-group-title=".list-group-title"
        hide-groups:false
        search-in=".item-title, .item-after, .item-subtitle, item-header, .item-footer"
      >
      </f7-searchbar>
      <f7-link
        popover-open=".sortingPopup"
        icon-ios="f7:sort_up"
        icon-aurora="f7:sort_up"
        icon-md="material:filter_list"
      ></f7-link>
    </div>
    <!-- <f7-card-header> -->
    <!-- </f7-card-header> -->
    <f7-card-content>
      <f7-list
        dividers
        strong-ios
        transaction-list
        class="searchbar-not-found"
        media-list
        v-if="!!this.transactionList && this.transactionList.length < 1"
      >
        <f7-list-item
          :title="$t('common.nothingFound')"
          style="font-size: 18px; color: red"
        >
        </f7-list-item>
      </f7-list>

      <f7-list
        dividers
        media-list
        accordion-list
        strong-ios
        class="transactions-list searchbar-found margin-bottom"
        v-if="this.transactionList && this.transactionList.length > 0"
        v-for="(transactions, index) in currentTransactionList(transactionList, groupBy)"
        :key="index"
      >
        <f7-list-group>
          <f7-list-item group-title class="margin-horizntal">
            <f7-chip
              :outline="theme.md ? false : true"
              color="primary"
              :text="
                groupBy === 'status'
                  ? transactionStatus(transactions.groupBy).status
                  : transactions.groupBy
              "
            >
            </f7-chip>
            <span style="font-size: 75%; font-style: italic; margin-left: 8px">
              <f7-icon
                ios="f7:arrow_up_circle"
                md="material:arrow_circle_up"
                color="primary"
                size="22px"
              ></f7-icon
              ><span
                v-html="
                  '+' +
                  $filtersToCurrency.toCurrency(
                    transactions.subTotalReceived,
                    '',
                    transactions.currency
                  )
                "
              ></span>
              <f7-icon
                ios="f7:arrow_down_circle"
                md="material:arrow_circle_down"
                color="red"
                size="22px"
              ></f7-icon
              ><span
                v-html="
                  '-' +
                  $filtersToCurrency.toCurrency(
                    transactions.subTotalPaid,
                    '',
                    transactions.currency
                  )
                "
              ></span>
              <f7-icon
                ios="f7:arrow_right_circle"
                md="f7:arrow_right_circle"
                color="gray"
                size="22px"
              ></f7-icon>
              <span
                :style="'color:' + transactions.subTotal < 0 ? 'red' : 'primary'"
                v-html="
                  $filtersToCurrency.toCurrency(
                    transactions.subTotal,
                    '',
                    transactions.currency
                  )
                "
              ></span>
            </span>
            <!-- :style="{
            'border-style': solid,
            ' border-width': '1px',
            'border-color': transactionStatus(transaction.status)
              ? transactionStatus(transaction.status).color
              : 'auto',
          }"  -->
          </f7-list-item>
          <f7-list-item
            v-for="(transaction, index) in transactions.items"
            :key="index"
            accordion-item
            :title="transaction.senderName"
            :footer="$dayjs(transaction.creation_date).format('llll')"
            :text="transaction.tag"
            :badge="
              transactionStatus(transaction.status)
                ? transactionStatus(transaction.status).status
                : transaction.status
            "
            :badge-color="
              transactionStatus(transaction.status)
                ? transactionStatus(transaction.status).color
                : 'primary'
            "
            :class="
              'highlighted_horiz border-color-' +
              transactionStatus(transaction.status).color
            "
          >
            <template #subtitle style="font-weight: bold"
              ><span
                v-if="transaction.status === 'CONFIRMED'"
                :style="
                  'color:' + transaction.walletActivity &&
                  transaction.walletActivity.type === 'CREDIT'
                    ? 'primary'
                    : 'red'
                "
              >
                <span
                  v-html="
                    transaction.walletActivity &&
                    transaction.walletActivity.type === 'CREDIT'
                      ? '+'
                      : '-'
                  "
                ></span>
                {{
                  $filtersToCurrency.toCurrency(
                    (transaction.amount || 0).toFixed(2),
                    null,
                    transaction.currency
                  )
                }}</span
              ><span v-else>
                {{
                  $filtersToCurrency.toCurrency(
                    (transaction.amount || 0).toFixed(2),
                    null,
                    transaction.currency
                  )
                }}</span
              >
            </template>
            <template #media>
              <img
                v-if="
                  transaction.senderUserId !== null &&
                  getCurrentUser(transaction.senderUserId) &&
                  getCurrentUser(transaction.senderUserId).avatar !== ''
                "
                :src="`${getCurrentUser(transaction.senderUserId).avatar}`"
                width="24"
              />
              <f7-badge
                v-else-if="transaction.senderName"
                color="auto"
                style="height: 48px; width: 48px; font-size: 16px; border-radius: 50%"
              >
                {{ $filtersGetInitial.getInitial(transaction.senderName) }}
              </f7-badge>
            </template>
            <f7-accordion-content>
              <f7-list dividers-ios strong-ios media-list>
                <f7-list-group v-if="transaction.status === 'AUTHORIZED'">
                  <span
                    v-if="
                      transaction.trxValidatorList.length > 0 &&
                      transaction.trxValidatorList.find(
                        (user) => user.useId === profile.sub
                      )
                    "
                  >
                    <div class="grid cols70_30">
                      <f7-input
                        type="text"
                        :label="$t('common.transferCode')"
                        disabled
                        readonly
                        class="padding-left-half"
                        :value="transaction.transferCode"
                      />

                      <f7-input
                        :label="$t('common.pin')"
                        type="text"
                        :placeholder="$t('common.pin')"
                        inputmode="numeric"
                        input-id="transferPIN"
                        :value="transaction.transferPIN"
                        @input="transferPIN = $event.target.value"
                        @keyup.enter="validateTransaction(transaction)"
                        pattern="[0-9]*"
                        maxlength="4"
                        size="4"
                        required
                      ></f7-input>
                    </div>
                    <f7-button
                      raised
                      fill
                      ripple
                      class="margin"
                      color="red"
                      strong
                      @click="validateTransaction(transaction)"
                      >{{ $t("common.validateTransfer") }}</f7-button
                    >
                  </span>
                  <f7-list-item v-else
                    >{{ $t("common.transferCode") }}:
                    {{ transaction.transferCode }}</f7-list-item
                  >
                </f7-list-group>
                <f7-list-group
                  v-if="
                    transaction.trxValidatorList &&
                    transaction.trxValidatorList.length > 0
                  "
                >
                  <f7-list-item
                    v-for="(approver, index) in transaction.trxValidatorList"
                    :key="index"
                    :title="approver.name"
                    :footer="
                      approver.isValidated
                        ? $dayjs(approver.validation_date).format('llll	')
                        : ''
                    "
                  >
                    <template #after>
                      <f7-chip
                        outline
                        :text="
                          approver.isValidated
                            ? $t('common.confirmed')
                            : $t('common.pending')
                        "
                        :color="approver.isValidated ? 'green' : 'deeporange'"
                      ></f7-chip
                    ></template>
                    <template #media>
                      <img
                        v-if="
                          approver.userId !== null &&
                          getCurrentUser(approver.userId) &&
                          getCurrentUser(approver.userId).avatar !== ''
                        "
                        :src="`${getCurrentUser(approver.userId).avatar}`"
                        width="24"
                      />
                      <f7-badge
                        v-else-if="approver.name"
                        color="auto"
                        style="
                          height: 24px;
                          width: 24px;
                          font-size: 12px;
                          border-radius: 50%;
                        "
                      >
                        {{ $filtersGetInitial.getInitial(approver.name) }}
                      </f7-badge>
                    </template>
                  </f7-list-item>
                </f7-list-group>
              </f7-list>
            </f7-accordion-content>
          </f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-card-content>
  </f7-card>
  <f7-popover
    class="sortingPopup"
    :backdrop="false"
    :params="{
      backdrop: false,
      closeOnEscape: true,
    }"
  >
    <f7-list dividers-ios strong-ios media-list>
      <f7-list-group>
        <f7-block-title medium>{{ $t("walletdetail.groupCampaignsBy") }}</f7-block-title>

        <f7-list-item
          popover-close
          v-for="criteria in this.groupingCriteria"
          :key="criteria.index"
          radio
          href="#"
          :value="criteria.value"
          :title="criteria.name"
          @change="
            (e) => {
              if (e.target.checked) groupBy = criteria.value;
            }
          "
          @click="setTransactionList(criteria.value, false)"
          name="groupBy"
        ></f7-list-item>
      </f7-list-group>
      <f7-list-group>
        <f7-block-title medium>{{ $t("walletdetail.sortCampaignsBy") }}</f7-block-title>
        <f7-list-item
          popover-close
          v-for="criteria in this.sortingCriteria"
          :key="criteria.index"
          link="#"
          class="sortBy-list"
          :title="criteria.name"
          @click="selectSortingCriteria(criteria.value)"
          ><template #after
            ><f7-icon
              class="sortingIcons"
              :id="'icon_' + criteria.value"
              :ios="isDesc ? 'f7:arrow_up_circle' : 'f7:arrow_down_circle'"
              :md="isDesc ? 'material:arrow_circle_up' : 'material:arrow_circle_down'"
            ></f7-icon></template
        ></f7-list-item>
      </f7-list-group>
    </f7-list>
  </f7-popover>
</template>
<style scoped>
.sortingIcons {
  display: none;
}

.sortBy-list:hover .sortingIcons {
  display: block;
}
</style>
<script>
import { f7, theme } from "framework7-vue";
import { transferAgain } from "../../js/mixins/transaction.js";
import { avocash_charts } from "../../js/mixins/charts.js";
import $dayjs from "../../js/day.js";
import { mapGetters, mapState } from "vuex";
import _ from "lodash";
export default {
  mixins: [transferAgain, avocash_charts],
  props: {
    f7route: Object,
    f7router: Object,
  },
  data() {
    return {
      theme,
      f7,
      orderBy: "creation_date",
      oderingDir: "desc",
      isDesc: true,
      groupBy: "transactionYearMonthDay",
      color: "",
      status: "",
      init: true,
      // transactionStatus: {},
      groupingCriteria: [
        { value: "transactionYearQuarter", name: this.$t("common.quarter") },
        { value: "transactionYearMonth", name: this.$t("common.paymentDate") },
        { value: "transactionYearMonthWeek", name: this.$t("common.paymentWeek") },
        { value: "transactionYearMonthDay", name: this.$t("common.paymentDay") },
        { value: "senderName", name: this.$t("common.sender") },
        { value: "status", name: this.$t("common.status") },
        { value: "currency", name: this.$t("common.currency") },
      ],
      sortingCriteria: [
        { value: "senderName", name: this.$t("common.sender") },
        { value: "creation_date", name: this.$t("common.paymentDate") },

        {
          value: "amount",
          name: this.$t("common.amount"),
        },
        {
          value: "status",
          name: this.$t("common.status"),
        },
      ],

      colors: [
        {
          color: "red",
          color_hex: "#ff3b30",
        },
        {
          color: "deeppurple",
          color_hex: "#673ab7",
        },
        {
          color: "deeporange",
          color_hex: "#ff6b22",
        },
        {
          color: "green",
          color_hex: "#4cd964",
        },
        {
          color: "pink",
          color_hex: "#ff2d55",
        },
        {
          color: "orange",
          color_hex: "#ff9500",
        },
        {
          color: "teal",
          color_hex: "#009688",
        },
        {
          color: "purple",
          color_hex: "#9c27b0",
        },
        {
          color: "lime",
          color_hex: "#cddc39",
        },
        {
          color: "blue",
          color_hex: "#2196f3",
        },
        {
          color: "lightblue",
          color_hex: "#5ac8fa",
        },
        {
          color: "yellow",
          color_hex: "#ffcc00",
        },
        {
          color: "#B5179E",
          color_hex: "#B5179E",
        },

        {
          color: "#086375",
          color_hex: "#086375",
        },
        {
          color: "#87677b",
          color_hex: "#87677b",
        },
      ],
    };
  },
  mounted() {
    this.setTransactionList("status", true);
  },

  methods: {},

  computed: {
    ...mapState("account", ["userAccount", "users"]),
    ...mapState("auth", [
      "profile",
      "documentsUrl",
      "document",
      "isTablet",
      "authenticated",
    ]),
    ...mapState("apimoney", [
      "bankAccount",
      "bankAccountList",
      "standard",
      "business",
      "accountList",
      "transactionList",
      "apiWallet",
      "structuredTransactionList",
    ]),
  },
};
</script>
