import { f7, theme } from "framework7-vue";
import _ from "lodash";
export const walletMixin = {
  mounted() {
    if (!this.publicWalletList || this.publicWalletList === "") {
      this.$store.dispatch("wallet/getPublicWalletList", this.visibleItem);
    }
    if (this.$keycloak.authenticated) {
      this.get3rdPartyWallet();
    }
    if (this.projectCategories === "") {
      this.$store.dispatch("wallet/getWalletCategory");
    }
    if (this.wallet) {
      this.share();
    }
  },
  methods: {
    createNew() {
      if (!this.actionsCampaign) {
        this.actionsCampaign = f7.actions.create({
          buttons: [
            {
              text: this.$t("common.selectAction"),
              label: true,
            },
            {
              text: this.$t("homepage.charitiesFunding"),
              icon: `<i class="material-icons color-green">volunteer_activism</i>`,
              color: "green",
              onClick() {
                f7.views.main.router.navigate("/wallet/new/charities");
              },
            },
            {
              text: this.$t("homepage.soloPubliccampaigns"),
              icon: `<i class="material-icons color-teal">reduce_capacity</i>`,
              color: "teal",
              onClick() {
                f7.views.main.router.navigate("/wallet/new/moneypots");
              },
            },
            {
              text: this.$t("homepage.artistsWebArtits"),
              icon: `<i class="material-icons color-teal">history_edu</i>`,
              color: "teal",
              onClick() {
                f7.views.main.router.navigate("/wallet/new/creatives");
              },
            },
            {
              text: this.$t("homepage.webEntrepreneurs"),
              icon: `<i class="f7-icons color-teal">sunrise</i>`,
              color: "teal",
              onClick() {
                f7.views.main.router.navigate("/wallet/new/entrepreneurs");
              },
            },
          ],
          // Need to specify popover target
          targetEl: this.$el.querySelector(".createCampaign"),
        });
      }

      // Open
      this.actionsCampaign.open();
    },

    async viewDetail(wallet) {
      f7.preloader.show("multi");
      if (wallet.id !== this.$store.state.wallet.wallet.id) {
        (await this.$store.state.wallet.wallet) == {};
        await this.$store.dispatch("wallet/getWallet", wallet.id);
      }
      f7.views.main.router.navigate(`/wallet/${wallet.id}`);
      f7.preloader.hide();
    },

    async addWalletOwner(searchedUser) {
      console.log(searchedUser);
      await this.$store.dispatch("wallet/addNewWalletOwner", {
        userId: searchedUser.userId,
        walletId: this.wallet.id,
      });
      f7.sheet.close(".addNewMember");
    },
    async removeWalletOwner(owner) {
      const self = this;

      await self.$store.dispatch("wallet/removeWalletOwner", {
        walletId: this.wallet.id,
        userId: owner.userId,
      });
    },
    async grantAdminRole(owner) {
      await this.$store.dispatch("wallet/grantAdminRole", {
        walletId: this.wallet.id,
        userId: owner.userId,
      });
    },
    async withdrawAdminRole(owner) {
      const self = this;

      await self.$store.dispatch("wallet/withdrawAdminRole", {
        walletId: this.wallet.id,
        userId: owner.userId,
      });
    },

    loadMore() {
      if (
        this.profile &&
        f7.views.main.router.currentRoute.path === "/wallets/private"
      ) {
        if (!this.allowInfinite) return;
        this.allowInfinite = false;
        if (
          this.privateWalletList &&
          this.privateWalletList.length < this.visibleItem
        ) {
          this.showPreloader = false;
          return;
        }
        setTimeout(() => {
          this.visibleItem += 6;
          this.$store.dispatch("wallet/getPrivateWalletList", this.visibleItem);
          this.allowInfinite = true;
        }, 1000);
      } else if (
        f7.views.main.router.currentRoute.path === "/" ||
        f7.views.main.router.currentRoute.path === "/wallets/"
      ) {
        if (!this.allowInfinite) return;
        this.allowInfinite = false;
        if (
          this.publicWalletList &&
          this.publicWalletList.length < this.visibleItem
        ) {
          this.showPreloader = false;
          return;
        }
        setTimeout(() => {
          this.visibleItem += 6;
          this.$store.dispatch("wallet/getPublicWalletList", this.visibleItem);
          this.allowInfinite = true;
        }, 1000);
      }
      this.showPreloader = false;
    },

    async validateRedeem(walletTransaction) {
      f7.preloader.show("multi");
      if (walletTransaction !== null) {
        await this.$store.dispatch("wallet/validateWalletRedeem", {
          transferCode: walletTransaction.transferCode,
          transferPIN: walletTransaction.transferPIN,
        });
      }
      this.validatedDialog();
      setTimeout(() => {
        f7.preloader.hide();
        if (
          f7.views.main.router.currentRoute.url.search(`/wallet/${wallet.id}`) <
          0
        ) {
          f7.views.main.router.back();
        }
        f7.views.main.router.refreshPage();
      }, 300);
    },

    validatedDialog() {
      if (!this.toastIcon) {
        this.toastIcon = f7.toast.create({
          icon: theme.ios
            ? '<i class="f7-icons text-color-green border-color-gray color-green size-100">check</i>'
            : '<i class="material-icons text-color-green border-color-gray color-green size-100">check</i>',
          cssClass: "toast_validate",
          position: "center",
          closeTimeout: 1500,
        });
      }
      this.toastIcon.open();
    },
    async share() {
      const files = [];
      const base64Files = [];
      if (
        this.wallet.walletImageList &&
        this.wallet.walletImageList.length > 0
      ) {
        for (const item of this.wallet.walletImageList) {
          // const blob = await (await fetch(walletImageItem.url)).blob();
          // const termination = blob.type.split("/")[1];
          // const file = new File(
          //   [blob],
          //   `${walletImageItem.name}.${termination}`,
          //   { type: blob.type }
          // );
          // //  : `data:${blob.type};base64,${this.wallet.walletImageList[i].url}`;
          // if (this.fileToSave) {
          //   this.fileToSave.push(file);
          // }
          files.push(item.url);
          // base64Files.push(walletImageItem.url);
        }
      }

      this.sharedContent = {
        message: this.wallet.tag,
        subject: this.wallet.name,
        files: files.push(this.wallet.qr),
        url: this.wallet.url,
        // base64Files,
        link: `${f7.views.main.router.currentRoute.path}`,
        short: false,
        linkBase: "links",
        imgUrl: "https://avocash.com/34x34.png",
      };

      // self.$store.dispatch("auth/createDynamicLink", this.sharedContent);
      console.log(this.sharedContent);
    },
    campaignDays(value) {
      const currentDate = new Date();
      const endDate = new Date(value.endDate);
      return endDate < currentDate
        ? self.$t("walletdetail.ended") +
            self.$t("walletdetail.daysago", {
              endedDays: ((currentDate - endDate) / (1000 * 3600 * 24)).toFixed(
                0
              ),
            })
        : self.$t("walletdetail.daysleft", {
            daysLeft: ((endDate - currentDate) / (1000 * 3600 * 24)).toFixed(0),
          });
    },
    getWalletPhotos(wallet) {
      const photos = [];
      for (const photo of wallet.walletImageList) {
        photos.push({
          url: `data:image/png;base64,${photo.image}`,
          name: photo.name,
        });
      }
      return photos;
    },

    get3rdPartyWallet() {
      if (
        !this.thirdPartyAllowedWalletList ||
        this.thirdPartyAllowedWalletList === ""
      ) {
        this.selectedCountry = this.profile.countryCode;
        this.$store.dispatch(
          "wallet/getThirdPartyAllowedWallets",
          this.selectedCountry
        );
      }
    },
  },

  computed: {
    recipientCountry() {
      if (this.wallet) {
        return _.find(this.destinationCountry, [
          "toCountryCode2",
          this.wallet.countryCode,
        ]);
      }
    },
    walletPhotos() {
      this.wallet.walletImageList?.map((item) => {
        return item.fileType === "video"
          ? {
              html: `<video src="${item.url}" controls ></video>`,
              caption: item.fileName,
            }
          : {
              url: item.url,
              caption: item.fileName,
            };
      });
    },

    currentWalletOwner() {
      if (this.$keycloak.authenticated) {
        return _.find(this.wallet.walletOwnerList, [
          "userId",
          this.profile.sub,
        ]);
      }
    },
    currentUserIsAdmin() {
      if (this.$keycloak.authenticated) {
        return _.find(this.wallet.walletAdminList, [
          "userId",
          this.profile.sub,
        ]);
      }
    },
  },
};
