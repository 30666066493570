<template>
  <f7-page
    hide-navbar-on-scroll
    infinite
    :infinite-distance="10"
    :infinite-preloader="showPreloader"
    @infinite="loadMore"
    @page:init="pageInit()"
  >
    <f7-navbar sliding no-shadow>
      <f7-nav-left
        :back-link="$t('common.backButton')"
        :back-link-show-text="false"
      ></f7-nav-left>
      <f7-nav-title>{{ $t("associationdetail.myCirclesassociations") }}</f7-nav-title>
      <f7-nav-right>
        <navbarright></navbarright>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <div style="padding-bottom: 80px" no-gap v-if="!this.associationList">
          <f7-card class="skeleton-text skeleton-effect-blink" v-for="n in 6" :key="n">
            <f7-card-header class="no-border">
              <div class="demo-facebook-avatar padding-right">
                <f7-badge
                  slot="media"
                  color="gray"
                  style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%"
                  >GT</f7-badge
                >
              </div>
              <div class="tontineTitle demo-facebook-name">Tontine name</div>
              <div class="demo-facebook-date tontineTitle">Tontine tag</div>
            </f7-card-header>
            <f7-card-content style="width: auto; height: 400px">
              <div class="timeline">
                <div class="timeline-item" v-for="x in 6" :key="x">
                  <div class="timeline-item-date">2021-11>2022-12</div>
                  <div class="timeline-item-divider"></div>
                  <div class="timeline-item-content">
                    <div class="timeline-item-title">Association name</div>
                    <div class="timeline-item-inner">
                      Collected
                      <span style="font-weight: bold; color: green"> 250 EUR</span
                      ><br />Balance
                      <span style="font-weight: bold"> 200 EUR </span>
                    </div>
                  </div>
                </div>
              </div>
            </f7-card-content>
            <f7-list dividers-ios strong-ios accordion-list media-list>
              <f7-list-item accordion-item :title="$t('common.description')">
                <f7-accordion-content>
                  <f7-block> </f7-block>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
            <f7-button
              fill
              large
              style="border-radius: 0px"
              color="gray"
              class="buttons"
              >{{ $t("common.seeDetail") }}</f7-button
            >
          </f7-card>
        </div>
        <div v-else-if="this.associationList === '' || this.associationList.length < 1">
          <span class="padding-left" style="text-align: center">{{
            $t("associationslist.noCircleassociation")
          }}</span>
          <a
            href="#"
            class="createGroup"
            @click="createNewGroup()"
            style="display: flex; justify-content: center"
          >
            <f7-chip
              color="red"
              outline
              :text="$t('associationdetail.createANewCircleassociation')"
              href="#"
            >
              <template #media>
                <f7-icon
                  color="red"
                  ios="f7:plus_circle_fill"
                  aurora="f7:plus_circle_fill"
                  md="material:add_circle"
                >
                </f7-icon>
              </template>
            </f7-chip>
          </a>
        </div>

        <f7-card
          class="demo-facebook-card"
          v-else-if="!!this.associationList && this.associationList.length >= 1"
          v-for="(association, index) in this.associationList"
          :key="index"
        >
          <span
            href="#"
            @click="viewDetail(association)"
            class="associationLink"
            style="color: none"
          >
            <f7-card-header :padding="false" class="no-margin">
              <div class="demo-facebook-avatar padding-right">
                <img
                  v-if="association.logo !== null"
                  :src="`${association.logo}`"
                  width="40"
                  height="40"
                  style="border-radius: 50%"
                />
                <f7-badge
                  v-else
                  slot="media"
                  color="auto"
                  style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%"
                  >{{ $filtersGetInitial.getInitial(association.name) }}</f7-badge
                >
              </div>
              <div class="associationTitle demo-facebook-name">
                {{ association.name }}
              </div>
              <div
                class="demo-facebook-date associationTitle"
                v-html="association.tag"
              ></div>
            </f7-card-header>

            <f7-card-content :padding="false" v-if="associationList">
              <swiper-container pagination :disableOnInteraction="true">
                <swiper-slide
                  :style="{
                    background:
                      'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',
                    width: '100%',
                    height: '400px',
                    'overflow-y': 'auto',
                  }"
                >
                  <div class="timeline">
                    <div class="timeline-item">
                      <div class="timeline-item-date">
                        {{ $t("common.total") }}
                      </div>
                      <div class="timeline-item-divider"></div>
                      <div class="timeline-item-content">
                        <div class="timeline-item-title">
                          {{ association.name }}
                        </div>
                        <div class="timeline-item-inner">
                          {{ $t("common.collected")
                          }}<span
                            style="font-weight: bold; color: green"
                            v-html="
                              $filtersToCurrency.toCurrency(
                                (association.collectedAmount || 0).toFixed(2),
                                null,
                                association.currencyCode
                              )
                            "
                          >
                          </span
                          ><br />{{ $t("common.balance")
                          }}<span
                            style="font-weight: bold"
                            v-html="
                              $filtersToCurrency.toCurrency(
                                (association.accountBalance || 0).toFixed(2),
                                null,
                                association.currencyCode
                              )
                            "
                          >
                          </span>
                        </div>
                      </div>
                    </div>
                    <div
                      v-for="campaign in myCampaigns(association)"
                      :key="campaign.id"
                      class="timeline-item"
                    >
                      <div
                        class="timeline-item-date"
                        v-if="!!campaign.startDate && !!campaign.endDate"
                      >
                        <small
                          >{{ campaign.startDate.substr(0, 7) }} >
                          {{ campaign.endDate.substr(0, 7) }}</small
                        >
                      </div>
                      <div class="timeline-item-divider"></div>
                      <div class="timeline-item-content">
                        <div class="timeline-item-text">
                          <f7-badge
                            width="24"
                            height="24px"
                            style="
                              height: 20px;
                              width: 20px;
                              font-size: 12px;
                              background-color: #0009ff;
                              color: white;
                            "
                            >{{ campaign.campaignNumber }}</f7-badge
                          >
                          {{ campaign.name }}
                        </div>
                        <div class="timeline-item-inner">
                          {{ $t("common.collected")
                          }}<span
                            style="font-weight: bold; color: green"
                            v-html="
                              $filtersToCurrency.toCurrency(
                                (campaign.collectedAmount || 0).toFixed(2),
                                null,
                                campaign.currencyCode
                              )
                            "
                          >
                          </span
                          ><br />{{ $t("common.balance")
                          }}<span
                            style="font-weight: bold"
                            v-html="
                              $filtersToCurrency.toCurrency(
                                (campaign.accountBalance || 0).toFixed(2),
                                campaign.currencySymbolNativenull,
                                campaign.currencyCode
                              )
                            "
                          >
                          </span>
                          <span
                            v-if="!!campaign.campaignBeneficiary"
                            style="font-style: italic"
                            ><br />{{ $t("common.beneficiary") }}:
                            {{
                              association.associationMemberList.find(
                                (x) => x.userId === campaign.campaignBeneficiary
                              ).name
                            }}</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
                <swiper-slide v-if="association.qr && association.qr.length > 0">
                  <span style="display: flex; justify-content: center">
                    <img
                      :src="`${association.qr}`"
                      style="display: block; margin: auto; position: absolute; top: 10%"
                  /></span>
                </swiper-slide>

                <swiper-slide
                  v-for="(imageUrl, index) in association.assoImageList"
                  :key="index"
                  v-if="association.assoImageList && association.assoImageList.length > 0"
                >
                  <span style="display: flex; justify-content: center">
                    <img
                      :src="`${imageUrl.url}`"
                      style="display: block; margin: auto; position: absolute; top: 10%"
                  /></span>
                </swiper-slide>
              </swiper-container>
            </f7-card-content>
          </span>
          <f7-list dividers-ios strong-ios accordion-list media-list>
            <f7-list-item accordion-item :title="$t('common.description')">
              <f7-accordion-content>
                <f7-block>
                  <p v-html="association.description"></p>
                </f7-block>
              </f7-accordion-content>
            </f7-list-item>
          </f7-list>
          <f7-button
            fill
            large
            class="card_button buttons"
            color="red"
            @click="viewDetail(association)"
            >{{ $t("common.seeDetail") }}</f7-button
          >
        </f7-card>
      </div>
      <div class="right_layout"></div>
    </div>
    <div style="padding-bottom: 100px !important"></div>
    <template #fixed>
      <f7-fab
        position="center-bottom createGroup"
        @click="createNewGroup()"
        :text="$t('associationdetail.createANewCircleassociation')"
        color="red"
        href="#"
      >
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Chip,
  f7Icon,
  f7Card,
  f7CardHeader,
  f7Badge,
  f7CardContent,
  f7List,
  f7ListItem,
  f7AccordionContent,
  f7Block,
  f7Button,
  f7Fab,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { association } from "../js/mixins/association.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Chip,
    f7Icon,

    f7Card,
    f7CardHeader,
    f7Badge,
    f7CardContent,

    f7List,
    f7ListItem,
    f7AccordionContent,
    f7Block,
    f7Button,
    f7Fab,
  },
  mixins: [calculateTransferPayment, association],
  data() {
    return {
      theme,
      f7,
      searchWallet: "",
      visibleItem: 6,
      allowInfinite: true,
      showPreloader: true,
    };
  },

  mounted() {
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.country === "" && !this.country) {
      this.$store.dispatch("location/getCountry");
    }
    this.$store.dispatch("asso/getUserAssociationsByuserId", {
      maxNumberOfItem: this.visibleItem,
    });
    if (this.participantList === "") {
      this.$store.dispatch("asso/getUserCoMemberList");
    }
    this.$store.dispatch("location/loadDestinationCity", this.profile.countryCode);
  },
  methods: {
    viewDetail(association) {
      f7.preloader.show("multi");
      this.$store.dispatch("asso/getAssociation", association.id);

      setTimeout(() => {
        console.log("association", this.association);
        this.f7router.navigate(`/association/${association.id}`);
        f7.preloader.hide();
      }, 1000);
      f7.preloader.hide();
    },

    myCampaigns(association) {
      return _.chain(association.associationCampaignList)
        .filter((campaign) => !_.includes(campaign.hiddenFrom, this.profile.sub))
        .orderBy(["campaignNumber"], ["desc"])
        .value();
    },

    loadMore() {
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      if (this.associationList && this.associationList.length < this.visibleItem) {
        this.showPreloader = false;
        return;
      }
      setTimeout(() => {
        this.visibleItem += 6;
        this.$store.dispatch("asso/getUserAssociationsByuserId", {
          maxNumberOfItem: this.visibleItem,
        });
        this.allowInfinite = true;
      }, 1000);
      this.showPreloader = false;
    },
    pageInit() {
      if (this.associationList === "") {
        this.$store.dispatch("asso/getUserAssociationsByuserId", {
          maxNumberOfItem: this.visibleItem,
        });
      }
    },
  },

  computed: {
    ...mapState("asso", [
      "association",
      "projectCategories",
      "member",
      "associationList",
      "memberList",
      "campaignTransaction",

      "tontineImages",
      "tontineQRImage",
    ]),
    ...mapState("location", ["destinationCity", "country", "currentUserCountry"]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile", "isTablet"]),
  },
};
</script>
