<template>
  <f7-page :hide-toolbar-on-scroll="false" :infinite-preloader="showPreloader" infinite :infinite-distance="50"
    @infinite="loadMore" class="wallets_page withMainToolbar" name="wallets">
    <f7-navbar sliding no-shadow v-if="!this.isTablet">
      <f7-nav-title>{{ $t("walletdetail.publicCampaigns") }}</f7-nav-title>
      <f7-nav-right v-if="this.publicWalletList && this.publicWalletList.length > 1">
        <f7-link class="searchbar-enable" data-searchbar=".searchbar-demo" icon-ios="f7:search" icon-aurora="f7:search"
          icon-md="material:search"></f7-link>
        <f7-link popover-open=".sortingPopup" icon-ios="f7:sort_up" icon-aurora="f7:sort_up"
          icon-md="material:filter_list"></f7-link>
      </f7-nav-right>
      <f7-searchbar class="searchbar-demo" :backdrop="false" :placeholder="$t('common.search')" expandable
        search-container=".search-list" search-item=".campaignTitleCard" search-in=".campaignTitle"
        search-group-title=".list-group-title" hide-groups:false :disable-button="!theme.aurora" clear-button>
      </f7-searchbar>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <span v-if="this.isTablet && this.publicWalletList && this.publicWalletList.length > 1
          " class="cols95_05">
          <!-- <f7-searchbar
        class="searchbar-demo"
        :value="storeSearch"
        :placeholder="$t('avocashstores.searchStores')"
        @input="storeSearch = $event.target.value"
        search-container=".search-list"
        search-item="div.itemImageContainer"
        search-in=".storeTags"
      ></f7-searchbar> -->
          <f7-searchbar class="searchbar-demo" :backdrop="false" :placeholder="$t('common.search')"
            search-container=".search-list" search-item=".campaignTitleCard" search-in=".campaignTitle"
            search-group-title=".list-group-title" hide-groups:false clear-button>
          </f7-searchbar>
          <f7-link popover-open=".sortingPopup" icon-ios="f7:sort_up" icon-aurora="f7:sort_up"
            icon-md="material:filter_list"></f7-link>
        </span>
        <f7-card outline class="demo-facebook-card campaignTitleCard skeleton-text skeleton-effect-blink"
          style="padding-bottom: 100px !important" v-for="n in 6" :key="n" v-if="!this.publicWalletList">
          <f7-card-header class="no-border">
            <div class="demo-facebook-avatar padding-right">
              <f7-badge slot="media" style="
                  height: 40px;
                  width: 40px;
                  font-size: 16px;
                  border-radius: 50%;
                  background-color: light-gray;
                ">RS</f7-badge>
            </div>
            <div class="campaignTitle demo-facebook-name">$t("common.name")</div>
            <div class="demo-facebook-date">$t("common.tag")</div>
          </f7-card-header>
          <f7-card-content :padding="false" style="width: auto; height: 400px">
            <span color="light-gray" style="height: auto; width: 400px">RS</span>
          </f7-card-content>
          <f7-list dividers-ios accordion-list media-list>
            <li class="item-content">
              <div class="item-inner">
                <div class="item-title-row">
                  <div class="item-title" style="color: gray">
                    {{ $t("common.collected") }}
                  </div>
                  <div class="item-title" style="font-weight: bold; color: gray">
                    500 EUR
                    <f7-badge color="gray">20</f7-badge>
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="color: gray">
                    {{ $t("common.balance") }}
                  </div>
                  <div class="item-title" style="font-weight: bold; color: gray">
                    2500 EUR
                  </div>
                </div>
                <div class="item-title-row">
                  <div class="item-title" style="color: gray">
                    {{ $t("common.target") }}
                  </div>
                  <div class="item-title" style="font-weight: bold; color: gray">
                    2000 EUR
                  </div>
                </div>
              </div>
            </li>
          </f7-list>
          <f7-button raised fill large class="no-margin-horiztontal buttons" color="gray">{{ $t("common.seeDetail")
          }}</f7-button>
        </f7-card>

        <div v-else-if="this.publicWalletList === '' ||
          (this.publicWalletList && this.publicWalletList.length < 1)
          ">
          <span class="padding-left" style="display: flex; justify-content: center">{{
            $t("common.nothingFound")
          }}</span>
          <a href="#" @click="createNew()" style="display: flex; justify-content: center" class="createCampaign">
            <f7-chip color="red" outline :text="$t('walletcreatenew.createMyCampaign')" href="#">
              <template #media>
                <f7-icon color="red" ios="f7:plus_circle_fill" aurora="f7:plus_circle_fill" md="material:add_circle">
                </f7-icon>
              </template>
            </f7-chip>
          </a>
        </div>
        <div v-else>
          <f7-list dividers-ios strong-ios class="searchbar-not-found">
            <f7-list-item :title="$t('common.nothingFound')" style="font-size: 18px; color: red"></f7-list-item>
          </f7-list>
          <f7-list dividers-ios class="search-list searchbar-found" accordion-list contact-list media-list
            v-for="(walletGroupList, walletGroup, index) in myWallets(publicWalletList)" :key="index" v-if="!!this.publicWalletList ||
              (this.publicWalletList && this.publicWalletList.length >= 1)
              ">
            <f7-list-group>
              <f7-list-item v-if="walletGroup !== true || walletGroup !== false" :title="walletGroup" group-title
                text-color="primary" style="font-size: 18px; border-radius: 16px"></f7-list-item>

              <f7-card class="demo-facebook-card campaignTitleCard" v-for="(wallet, index) in walletGroupList"
                :key="index" header-divider>
                <f7-card-header class="no-border">
                  <div class="demo-facebook-avatar padding-right">
                    <img v-if="wallet.logo !== null" :src="`${wallet.logo}`" width="40" height="40"
                      style="border-radius: 50%" alt="campaign logo" />
                    <f7-badge v-else slot="media" color="auto" style="
                        height: 40px;
                        width: 40px;
                        font-size: 16px;
                        border-radius: 50%;
                      ">{{ $filtersGetInitial.getInitial(wallet.name) }}</f7-badge>
                  </div>
                  <div class="campaignTitle demo-facebook-name">
                    {{ wallet.name }}
                  </div>
                  <div class="demo-facebook-date campaignTitle">
                    {{ wallet.tag }}
                  </div>
                </f7-card-header>
                <f7-card-content :padding="false" style="
                    height: 400px;
                    width: 100%;
                    background: linear-gradient(
                      90deg,
                      rgba(131, 58, 180, 1) 0%,
                      rgba(29, 253, 36, 1) 50%,
                      rgba(252, 176, 69, 1) 100%
                    );
                  ">
                  <swiper-container :pagination="true" :slidesPerView="1" :spaceBetween="0" :centeredSlides="true">
                    <f7-photo-browser :photos="wallet.walletImageList" ref="popupWallet"></f7-photo-browser>
                    <swiper-slide>
                      <a link="#" @click="$refs.popup.open()">
                        <img :style="{
                          display: 'block',
                          margin: 'auto',
                          width: 'auto',
                          'margin-top': '55px',
                          'margin-bottom': '55px',
                        }" :src="wallet.qr" />
                      </a>
                    </swiper-slide>
                    <swiper-slide v-for="(imageUrl, index) in walletPhotos" :key="index">
                      <a link="#" @click="$refs.popup.open()">
                        <img :src="imageUrl.url" v-if="imageUrl.fileType === 'image'" :style="{
                          display: 'block',
                          margin: 'auto',
                          height: 'auto',
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          width: '100%',
                        }" :alt="imageUrl.fileName" />
                        <video v-if="imageUrl.fileType === 'video'" :src="imageUrl.url" controls
                          :alt="imageUrl.fileName" />
                      </a>
                    </swiper-slide>
                  </swiper-container>
                </f7-card-content>
                <f7-list-item :title="$t('walletdetail.fundraiser')"
                  :after="wallet.type === 'charities' ? wallet.name : wallet.ownerName"></f7-list-item>
                <f7-list-item :title="$t('common.collected')" :after="$filtersToCurrency.toCurrency(
                  (wallet.collectedAmount || 0).toFixed(2),
                  null,
                  wallet.currencyCode
                )
                  "></f7-list-item>
                <f7-list-item accordion-item :title="$t('common.description')" :padding="false">
                  <f7-accordion-content>
                    <f7-block>
                      <p v-html="wallet.description"></p>
                    </f7-block>
                  </f7-accordion-content>
                </f7-list-item>
                <f7-button raised fill large ripple color="red" class="card_button buttons" @click="viewDetail(wallet)">{{
                  $t("common.seeDetail") }}</f7-button>
              </f7-card>
            </f7-list-group>
          </f7-list>
        </div>
        <div class="right_layout"></div>
      </div>
    </div>
    <div style="padding-bottom: 100px !important"></div>
    <f7-popover class="sortingPopup" :backdrop="false" :params="{
      backdrop: false,
      closeOnEscape: true,
    }">
      <f7-list dividers-ios strong-ios media-list>
        <f7-list-group>
          <f7-block-title medium>{{
            $t("walletdetail.groupCampaignsBy")
          }}</f7-block-title>

          <f7-list-item v-for="criteria in this.groupingCriteria" :key="criteria.index" radio href="#"
            :value="criteria.value" :title="criteria.name" @change="(e) => {
                if (e.target.checked) groupCriteria = criteria.value;
              }
              " name="groupCriteria"></f7-list-item>
        </f7-list-group>
      </f7-list>
    </f7-popover>
    <f7-fab position="center-bottom createCampaign" slot="fixed" :text="$t('walletcreatenew.createMyCampaign')"
      color="red" href="#" @click="createNew()">
      <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
    </f7-fab>
  </f7-page>
</template>
<style scoped>
.walletLink {
  color: inherit;
}

.walletImage {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 400px;
  width: 100%;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7NavRight,
  f7Link,
  f7Searchbar,
  f7Chip,
  f7List,
  f7ListItem,
  f7ListGroup,
  f7Card,
  f7CardHeader,
  f7Badge,
  f7CardContent,
  f7AccordionContent,
  f7Block,
  f7Button,
  f7Popover,
  f7BlockTitle,
  f7Fab,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { walletMixin } from "../js/mixins/walletMixins.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7NavRight,
    f7Link,
    f7Searchbar,
    f7Chip,
    f7List,
    f7ListItem,
    f7ListGroup,

    f7Card,
    f7CardHeader,
    f7Badge,
    f7CardContent,

    f7AccordionContent,
    f7Block,
    f7Button,
    f7Popover,
    f7BlockTitle,
    f7Fab,
    f7Icon,
  },
  mixins: [calculateTransferPayment, walletMixin],
  data() {
    return {
      theme,
      f7,
      theme,
      searchWallet: "",
      visibleItem: 6,
      allowInfinite: true,
      showPreloader: false,
      groupCriteria: "categoryName",
      groupingCriteria: [
        { value: "categoryName", name: this.$t("walletdetail.categoryName") },
        {
          value: "countryName",
          name: this.$t("walletdetail.country"),
        },
        { value: "thirdPartyCampaign", name: this.$t("walletdetail.thirdPartyCampaign") },
        { value: "currencyCode", name: this.$t("walletdetail.currency") },
      ],
    };
  },
  mounted() {
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (!this.publicWalletList) {
      this.$store.dispatch("wallet/getPublicWalletList", this.visibleItem);
    }
    if (this.publicWalletList) {
      this.myWallets(this.publicWalletList);
    }
    if (this.country === "" || !this.country) {
      this.$store.dispatch("location/getCountry");
    }
    this.countryId = this.currentUserCountry.countryId;
    // if (this.isTablet == true) {
    //   f7.$("div.page-content").attr("style", "--f7-page-navbar-offset:0px");
    // }
  },
  methods: {
    myWallets(publicWalletList) {
      // Optimized to avoid multiple loops and unnecessary finds
      return (
        _.chain(publicWalletList.slice())
          .map((wallet) => {
            const category = _.find(this.projectCategories, [
              "id",
              parseInt(wallet.categoryId),
            ]);
            const country = _.find(this.country, ["countryCode2", wallet.countryCode]);

            return _.assign(wallet, {
              categoryName: category.categoryName,
              countryName: country.countryName,
            });
          })
          // .forEach((wallet) => {
          //   wallet.walletImageList.forEach((item) => {
          //     _.assign(item, {
          //       url: `${item.url}`,
          //     });
          //   });
          // })
          // .orderBy([this.sortCriteria], ["desc"])
          .groupBy(this.groupCriteria)
          .value()
      );
    },
  },
  computed: {
    ...mapState("wallet", [
      "wallet",
      "projectCategories",
      "walletOwner",
      "walletList",
      "privateWalletList",
      "publicWalletList",
      "walletTransaction",
      "thirdPartyAllowedWalletList",
      "privateWalletTransactionList",
      "publicWalletTransactionList",
      "walletTransactionList",
      "walletImages",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "searchedUser"]),
    ...mapState("auth", ["profile", "isDeskTop", "isTablet"]),
    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),

    destinationCtry() {
      return _.groupBy(this.country, "continentName");
    },
  },
};
</script>
