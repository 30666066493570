<template>
  <div class="list media-list">
    <ul>
      <li class="item-content item-input">
        <div class="item-inner">
          <div class="item-title item-label">
            {{ $t("avocashUsers.find") }}
          </div>
          <div class="item-input-wrap">
            <input
              type="text"
              @blur="clearSearch()"
              @keydown="updateList()"
              :placeholder="$t('common.searchAvoashUser')"
              id="autocomplete-dropdown-user-search"
            />
            <span class="input-clear-button" @click="clearSearch()"></span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<style scoped>
.userImage img {
  width: 48px;
  border-radius: 24px;
}
</style>
<script>
import { theme, f7 } from "framework7-vue";
import { mapState, mapGetters } from "vuex";
import _ from "lodash";
import { utils } from "framework7";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  data() {
    return { theme, f7, selectedItem: "", description: "" };
  },
  mounted() {
    if (this.$keycloak.authenticated && !this.users) {
      this.$store.dispatch("account/getAllUsers");
    }

    if (f7.$("#autocomplete-dropdown-user-search").val() === "") {
      this.$store.dispatch("account/setUser", "");
    }
    this.getUserList();
    console.log(this.users);
  },
  methods: {
    updateList() {
      if (this.$keycloak.authenticated && this.users === null) {
        this.$store.dispatch("account/getAllUsers");
      }
    },
    getUserList() {
      const self = this;

      self.autocompleteDropdownAjaxTypeahead = f7.autocomplete.create({
        inputEl: "#autocomplete-dropdown-user-search",
        openIn: "dropdown",
        preloader: true, // enable preloader
        preloaderColor: "multi",
        valueProperty: "name",
        textProperty: "name",
        limit: 20, // limit to 20 results
        typeahead: true,
        dropdownPlaceholderText: self.$t("common.searchAvoashUser"),
        // "Search user, type in name, avocash tag '¢kalebe', phone number, username",
        notFoundText: self.$t("common.nothingFound"),
        // dropdownContainerEl: "#autocompleteList",
        renderItem(item) {
          if (item.placeholder) {
            return ` <li class=" item-content">
                        <div class="item-inner">
                          <div class="item-title-row">
                            <div class="item-title">${item.text}</div>
                          </div>
                        </div>
                    </li>`;
          }
          for (const user of self.users) {
            if (user["name"] === item["value"]) {
              utils.extend(item, user);
              break;
            }
          }
          const initials = item.name ? self.$filtersGetInitial.getInitial(item.name) : "";
          if (item.logo === null) {
            var pict = `<span v-else color="auto" class="badge" slot="media" style="height: 32px; width: 32px; font-size: 14px; background-color:var(--f7-theme-color) ">${initials}</span>`;
          } else {
            var pict = `<img src="${item.logo}" class="userImage" v-if="${item.logo}" width="40" alt="user avatar or logo"   style="height: 32px; width: 32px; border-radius: 25px" slot="media"/>`;
          }

          return `<div class="list media-list  ">
                        <ul class="padding-left no-margin-left">
                          <li class="no-margin-left no-padding-left">
                          <label class="item-radio item-content  no-margin-left no-padding-left" data-value="${
                            item.value
                          }"   @click="selectUser(${item})">
                              <div class="item-media">${pict}</div>
                              <div class="item-inner">
                                  <div class="item-title">${item.text}</div>
                                  <div class="item-subtitle">${item.username}</div>
                                <div class="item-footer avocash">${item.tag || " "}</div>
                              </div>
                            </label>
                          </li>
                        </ul>
                      </div>`;
        },
        source(query, render) {
          var autocomplete = this;
          const results = [];
          if (query.length === 0) {
            render(results);
            return;
          }
          autocomplete.preloaderShow();

          for (const user of self.users) {
            if (
              user.name.toLowerCase().toString().includes(query.toLowerCase()) ||
              user.phoneNumber.toLowerCase().toString().includes(query.toLowerCase()) ||
              user.email !== null
                ? user.email
                    .substring(0, user.email.toString().indexOf("@"))
                    .toLowerCase()
                    .includes(query.toLowerCase())
                : null ||
                  user.username.toLowerCase().toString().includes(query.toLowerCase()) ||
                  _.findIndex(user.tag.toLowerCase(), query.toLowerCase()) >= 0 ||
                  user.walletId !== null
                ? user.walletId.toLowerCase().toString().includes(query.toLowerCase())
                : null
            )
              results.push(user);
          }
          console.log(results);
          // Hide Preoloader
          autocomplete.preloaderHide();
          // Render items by passing array with result items
          render(results);
        },
        on: {
          change(value) {
            console.log(value);
            self.$store.dispatch("account/setUser", value[0]);
          },
        },
      });
    },
    clearSearch() {
      if (f7.$("#autocomplete-dropdown-ajax-typeahead").val() === "") {
        this.$store.dispatch("account/setUser", "");
      }
      this.$store.dispatch("account/setUser", "");
    },
  },
  computed: {
    ...mapState("account", ["userAccount", "searchedUser", "users"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),
  },
};
</script>
