// import base_url from "../base_url.js";
import axios from "axios";
const path = import.meta.env.VITE_APP_URL + "document";
export default (app) => {
  return {
    namespaced: true,
    state: {
      sharedDocuments: "",
      sharedDocList: [],
      sharedFile: "",
    },
    getters: {},
    mutations: {
      GET_ENTITY_DOC_LIST(state, sharedDocList) {
        state.sharedDocList = sharedDocList;
      },
    },

    actions: {
      //get entity documents
      createSharedDocuments({ commit }, payload) {
        axios({
          url: path,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((sharedDocList) => {
            commit("GET_ENTITY_DOC_LIST", sharedDocList);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
      getSharedDocumentList({ commit }, payload) {
        axios({
          url: path,
          method: "GET",
          params: { entityId: payload },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((sharedDocList) => {
            commit("GET_ENTITY_DOC_LIST", sharedDocList);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
      editNewSharedDocuments({ commit }, payload) {
        axios({
          url: path,
          method: "PUT",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((sharedDocList) => {
            commit("GET_ENTITY_DOC_LIST", sharedDocList);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
      deleteSharedDocuments({ commit }, payload) {
        axios({
          url: path,
          method: "DELETE",
          params: { docId: payload.docId, isAdmin: payload.isAdmin },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((sharedDocList) => {
            commit("GET_ENTITY_DOC_LIST", sharedDocList);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
      deleteSharedFile({ commit }, payload) {
        axios({
          url: `${path}/file`,
          method: "DELETE",
          params: { fileId: payload.fileId, isAdmin: payload.isAdmin },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((sharedDocList) => {
            commit("GET_ENTITY_DOC_LIST", sharedDocList);
          })
          .catch((err) => {
            // console.log(err.response.data);
            console.log(err);
          });
      },
    },
  };
};
// export default account;
