import { f7 } from "framework7-vue";
import _ from "lodash";
export const groupbuy = {
  mounted() {
    const self = this;
    if (this.groupBuy) {
      this.share();
    }
  },
  methods: {
    //Tontine List
    async share() {
      // const self = this;
      // const files = [];
      // const base64Files = [];
      // if (
      //   this.groupBuy &&
      //   this.groupBuy.qrCod &&
      //   this.groupBuy.qrCode.length > 0
      // ) {
      //   const blob = await (
      //     await fetch(`data:image/png;base64,${this.groupBuy.qrCode}`)
      //   ).blob();
      //   const termination = blob.type.split("/")[1];
      //   const file = new File([blob], `qrCode.${termination}`, {
      //     type: blob.type,
      //   });
      //   files.push(file);
      //   base64Files.push(`data:image/png;base64,${this.groupBuy.qrCode}`);
      //   // }
      // }

      this.sharedContent = {
        message: `${this.groupBuy.tag}\n\n${this.groupBuy.description}`,
        subject: this.groupBuy.name,
        // base64Files,
        files: [this.groupBuy.qr],
        link: `${f7.views.main.router.currentRoute.path}`,
        short: false,
        linkBase: "links",
        url: this.groupBuy.url,
        imgUrl: "https://avocash.com/34x34.png",
      };
    },

    createNewGroupBuy() {
      this.f7router.navigate("/groupBuy/new");
    },

    createNewProject() {
      const self = this;

      if (!self.actionsBattle) {
        self.actionsBattle = f7.actions.create({
          buttons: [
            {
              text: self.$t("common.selectAction"),
              label: true,
            },
            {
              text: self.$t("homepage.groupFriendFamily"),
              icon: `<i class="material-icons color-teal md-only">groups</i><i class="f7-icons color-teal if-not-md">person_3_fill</i>`,
              color: "teal",
              onClick() {
                self.f7router.navigate("/groupBuy/new/project");
              },
            },
            {
              text: self.$t("homepage.associations"),
              icon: `<i class="material-icons color-green">group_work</i>`,
              color: "green",
              onClick() {
                self.f7router.navigate("/groupBuy/new/project");
              },
            },
          ],
          // Need to specify popover target
          targetEl: self.$el.querySelector(".createBatlle"),
        });
      }

      // Open
      self.actionsBattle.open();
    },
    async selectProject(project) {
      f7.tab.show(`#tab-${project.projectNumber}`, true);
      this.currentProject = project;
    },
    async removeParticipant(participant) {
      await this.$store.dispatch("groupBuy/removeParticipant", {
        userId: participant.userId,
        groupBuyId: this.groupBuy.id,
      });
    },
    grantAdminRole(participant) {
      this.$store.dispatch("groupBuy/grantAdminRole", {
        groupBuyId: this.groupBuy.id,
        userId: participant.userId,
      });
    },
    withdrawAdminRole(participant) {
      const self = this;

      self.$store.dispatch("groupBuy/withdrawAdminRole", {
        groupBuyId: this.groupBuy.id,
        userId: participant.userId,
      });
    },
    projectContribution(project) {
      return _.reduce(
        project.participantList,
        (amount, participant) => {
          return amount + parseFloat(participant.paidContrib || 0);
        },
        0
      );
    },
  },
  computed: {
    myProjects() {
      return _.chain(this.groupBuy.projectList)
        .orderBy(["projectNumber"], ["desc"])
        .value();
    },
  },
};
