import { f7, theme } from "framework7-vue";
import { utils } from "framework7";
import _ from "lodash";
export const shop = {
  mounted() {
    this.share();
  },
  methods: {
    async share() {
      // const self = this;

      // const files = [];
      // const base64Files = [];
      // if (this.shop.qrImage) {
      //   const blob0 = await (
      //     await fetch(`data:image/png;base64,${this.shop.qrImage}`)
      //   ).blob();
      //   const termination = blob0.type.split("/")[1];
      //   const file0 = new File([blob0], `shopQRImage.${termination}`, {
      //     type: blob0.type,
      //   });
      //   files.push(file0);
      //   base64Files.push(`data:image/png;base64,${this.shop.qrImage}`);
      // }
      // if (this.shop.logo) {
      //   const blob1 = await (
      //     await fetch(`data:image/png;base64,${this.shop.logo}`)
      //   ).blob();
      //   const termination = blob1.type.split("/")[1];
      //   const file1 = new File([blob1], `shopLogo.${termination}`, {
      //     type: blob1.type,
      //   });
      //   files.push(file1);
      //   base64Files.push(`data:image/png;base64,${this.shop.logo}`);
      // }

      // if (this.shopPhotos && this.shopPhotos.length > 0) {
      //   for (const shopPhoto of this.shopPhotos) {
      //     const blob = await (await fetch(shopPhoto.url)).blob();
      //     const termination = blob.type.split("/")[1];
      //     const file = new File([blob], `${shopPhoto.name}.${termination}`, {
      //       type: blob.type,
      //     });
      //     files.push(file);
      //     base64Files.push(shopPhoto.url);
      //   }
      // }

      this.sharedContent = {
        message: this.shop.tag,
        subject: this.shop.name,
        files: [this.shop.qr, this.shop.logo],
        // base64Files,
        url: this.shop.url,
        link: `${this.f7router.currentRoute.path}`,
        short: false,
        linkBase: "links",
        imgUrl: "https://avocash.com/34x34.png",
      };

      // self.$store.dispatch("auth/createDynamicLink", this.sharedContent);
      console.log(this.sharedContent);
    },
    /*    async addToCart(selectedItem) {
                                                       const self = this;


                                                       if (this.cart.length === 0) {
                                                           var now = await utils.now();
                                                           window.localStorage.setItem('cartDuration', now);
                                                       }
                                                       await self.$store.dispatch("shoppingCart/addToCart", {
                                                           item: _.assign(selectedItem, {
                                                               quantity: this.quantity ? this.quantity : 1,
                                                           }),
                                                       });
                                                       // await f7router.refreshPage();
                                                   },
                                                   async removeFromCart(selectedItem) {
                                                       const self = this;


                                                       await self.$store.dispatch("shoppingCart/removeFromCart", selectedItem);
                                                       if (this.cart.length > 0) {
                                                           await f7router.refreshPage();
                                                       } else {
                                                           await f7router.back();
                                                       }
                                                   },
                                                   async deleteItemFromCart(selectedItem) {
                                                       const self = this;


                                                       await self.$store.dispatch(
                                                           "shoppingCart/deleteItemFromCart",
                                                           selectedItem
                                                       );
                                                       if (this.cart.length > 0) {
                                                           await f7router.refreshPage();
                                                       } else {
                                                           await f7router.back();
                                                       }
                                                   },
                                                   async deleteCart() {
                                                       const self = this;


                                                       await self.$store.dispatch("shoppingCart/deleteCart");
                                                       await f7router.back();
                                                   },
                                                   async buyNow(selectedItem) {
                                                       const self = this;


                                                       await self.$store.dispatch("shoppingCart/addToCart", {
                                                           item: _.assign(selectedItem, {
                                                               quantity: this.quantity ? this.quantity : 1,
                                                           }),
                                                       });
                                                       await this.f7router.navigate("/check-out");
                                                   } */
  },
  computed: {
    shopGroupList() {
      if (this.shopList && this.shopList.length > 0) {
        return _.chain(this.shopList.slice())
          .map((shop) =>
            _.extend(
              {
                ownership:
                  shop.shopOwnerId === this.profile.sub
                    ? "my shops"
                    : "managed shops",
              },
              shop
            )
          )
          .groupBy("ownership")
          .value();
      }
    },
    shopPhotos() {
      this.shop.shopMediaList?.map((item) => {
        return item.fileType === "video"
          ? {
              html: `<video src="${item.url}" controls ></video>`,
              caption: item.fileName,
            }
          : {
              url: item.url,
              caption: item.fileName,
            };
      });
    },
  },
};

// import _ from "lodash";
//export const selectedRecipient = {
//     data() {},
//     mounted() {
//         const self = this;
//
//     },
//     methods: {},
//     computed: {
//         recipient() {
//             const self = this;
//
//             const id = this.f7route.params.id || this.recipientId;
//             for (var i = 0; i < this.allrecipients.length; i += 1) {
//                 if (this.allrecipients[i].id == id) {
//                     return this.allrecipients[i];
//                 }
//             }
//         },

//         recipientCountry() {
//             if (this.recipient) {
//                 return _.find(this.destinationCountry, [
//                     "toCountryId",
//                     this.recipient.countryId
//                 ]);
//             }
//         }
//     }
// };
