import axios from "axios";
import daysjs from "../../day.js";
const path = import.meta.env.VITE_APP_URL + "post";
import _ from "lodash";

//  const message={
export default (app) => {
  return {
    namespaced: true,
    state: {
      msgCltConnected: false,
      messageSocketClient: "",
      deviceToken: "",
      post: "",
      postList: [],
      userPostList: [],
      groupPostList: [],
      comment: "",
    },
    getters: {},
    mutations: {
      MSG_CONNECT_SOCKET(state, msgCltConnected) {
        state.msgCltConnected = msgCltConnected;
      },

      MSG_CONNECT_SOCKET_CLIENT(state, messageSocketClient) {
        state.messageSocketClient = messageSocketClient;
      },

      CREATE_POST(state, post) {
        state.post = post;
      },
      GET_POSTLIST(state, postList) {
        state.postList = postList;
      },
      GET_POST_LIST(state, postList) {
        state.postList = state.postList.push(...postList);
      },
      GET_USER_POST_LIST(state, payload) {
        state.userPostList.push(payload);
      },
      GET_GROUP_POST_LIST(state, payload) {
        state.groupPostList.push(payload);
      },
      UPDATE_POST(state, criteria) {
        const index = state.postList.findIndex(
          (post) => post.id === criteria.postId
        );
        if (index > -1) {
          // Create a new notification object with updated properties
          const updatedPost = {
            ...state.postList[index],
            has_deleted: has_deleted.push(criteria.userId),
          };
          // Replace the old notification with the updated one
          state.postList.splice(index, 1, updatedPost);
        }
      },
      DELETE_POST(state, criteria) {
        const index = state.postList.findIndex(
          (post) => post.id === criteria.postId
        );

        if (index > -1) {
          // Create a new notification object with updated properties
          const updatedPost = {
            ...state.postList[index],
            has_deleted: has_deleted.push(criteria.userId),
          };
          // Replace the old notification with the updated one
          state.postList.splice(index, 1, updatedPost);
        }
      },
      HIDE_POST(state, criteria) {
        const index = state.postList.findIndex(
          (post) => post.id === criteria.postId
        );

        if (index > -1) {
          // Create a new notification object with updated properties
          const updatedPost = {
            ...state.postList[index],
            hidden: hidden.push(criteria.userId),
          };
          // Replace the old notification with the updated one
          state.postList.splice(index, 1, updatedPost);
        }
      },

      UPDATE_POST_LIST(state, payload) {
        state.postList.push(payload);
      },
    },

    actions: {
      msgConnectSocketed({ commit }, msgCltConnected) {
        commit("MSG_CONNECT_SOCKET", msgCltConnected);
        console.log("msgConnectSocketed", msgCltConnected);
      },

      msgConnectSocketClient({ commit }, messageSocketClient) {
        commit("MSG_CONNECT_SOCKET_CLIENT", messageSocketClient);
        console.log("msgConnectSocketedclient", messageSocketClient);
      },

      createPost({ commit }, payload) {
        axios({
          url: `${path}/create`,
          method: "POST",
          data: payload,
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("CREATE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      publishPost({ commit }, payload) {
        axios({
          url: `${path}/publish`,
          method: "PUT",
          params: {
            postId: payload.postId,
            isPosted: payload.isPosted,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("CREATE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      isCommentable({ commit }, payload) {
        axios({
          url: `${path}/publish`,
          method: "PUT",
          params: {
            postId: payload.postId,
            isCommentable: payload.isCommentable,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("CREATE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      updatePost({ commit }, payload) {
        axios({
          url: `${path}/create`,
          method: "PUT",
          data: payload.post,
          params: {
            postId: payload.postId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("CREATE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      repostPost({ commit }, payload) {
        axios({
          url: `${path}/repost`,
          method: "POST",
          data: payload.post,
          params: {
            postId: payload.postId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("CREATE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      hidePost({ commit }, payload) {
        axios({
          url: `${path}/hide`,
          method: "PUT",
          params: {
            postId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("HIDE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      reactToPost({ commit }, payload) {
        axios({
          url: `${path}/react`,
          method: "PUT",
          params: {
            itemId: payload.itemId,
            interactionType: payload.interactionType,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("CREATE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      commentPost({ commit }, payload) {
        axios({
          url: `${path}/comment`,
          method: "POST",
          data: payload.comment,
          params: {
            postId: payload.postId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("CREATE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      subCommentPost({ commit }, payload) {
        axios({
          url: `${path}/sub_comment`,
          method: "POST",
          data: payload.comment,
          params: {
            commentId: payload.commentId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("CREATE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      updateComment({ commit }, payload) {
        axios({
          url: `${path}/comment/update`,
          method: "POST",
          data: payload.comment,
          params: {
            commentId: payload.commentId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("CREATE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      deleteComment({ commit }, payload) {
        axios({
          url: `${path}/comment/delete`,
          method: "DELETE",
          params: {
            commentId: payload.commentId,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((post) => {
            commit("CREATE_POST", post);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getPostList({ commit }, payload, state) {
        axios({
          url: `${path}/posts`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          params: {
            limit: payload.limit,
            skip: payload.skip,
          },
        })
          .then((r) => r.data)
          .then((postList) => {
            commit("GET_POST_LIST", postList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },

      getUserCreatedPost({ commit }, payload) {
        axios({
          url: `${path}/user/posts`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          params: {
            limit: payload.limit,
            skip: payload.skip,
          },
        })
          .then((r) => r.data)
          .then((userPostList) => {
            commit("GET_USER_POST_LIST", userPostList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      getGroupCreatedPost({ commit }, payload) {
        axios({
          url: `${path}/user/posts`,
          method: "GET",
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
          params: {
            limit: payload.limit,
            skip: payload.skip,
            groupId: payload.groupId,
          },
        })
          .then((r) => r.data)
          .then((groupPostList) => {
            commit("GET_GROUP_POST_LIST", groupPostList);
          })
          .catch((err) => {
            console.log(err.response.data);
          });
      },
      deletePost({ commit }, payload) {
        axios({
          url: `${post}/delete`,
          method: "PUT",
          params: {
            postId: payload,
          },
          headers: {
            Authorization: `Bearer ${app.config.globalProperties.$keycloak.token}`,
          },
        })
          .then((r) => r.data)
          .then((response) => {
            console.log("post delete", response);
            const criteria = {
              postId: payload,
              userId: app.config.globalProperties.$keycloak.subject,
            };
            commit("DELETE_POST", criteria);
          })
          .catch((err) => {
            console.log(err);
          });
      },

      updatePostList({ commit }, payload) {
        commit("UPDATE_POST_LIST", payload);
      },
    },
  };
};
// export default message;
