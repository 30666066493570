<template>
  <f7-popup
    v-if="this.association.associationCampaignList !== null"
    class="addNewCampaign"
    style="--f7-sheet-bg-color: #fff"
    swipe-to-close
    :tablet-fullscreen="true"
    :params="{
      backdrop: true,
      animate: true,
      swipeToClose: true,
      closeByBackdropClick: true,
      closeOnEscape: true,
    }"
    ><f7-view>
      <f7-page>
        <f7-navbar>
          <div class="left"></div>
          <div class="title">
            {{
              $t("associationdetail.addNewProjectcampaign", {
                campaignType: getCampaignType.toLowerCase(),
              })
            }}
          </div>
          <div class="right">
            <f7-link popup-close icon-f7="xmark_circle"></f7-link>
          </div>
        </f7-navbar>
        <div class="layout_content">
          <div class="left_layout"></div>
          <div class="main_layout">
            <f7-list dividers-ios strong-ios media-list form id="addNewCampaign">
              <f7-list-group>
                <f7-list-input
                  outline
                  required
                  validate
                  :placeholder="
                    $t('associationdetail.campaignprojectName', {
                      campaignType: this.getCampaignType,
                    })
                  "
                  type="text"
                  name="campaign"
                  floating-label
                  :label="
                    $t('associationdetail.campaignprojectName', {
                      campaignType: this.getCampaignType,
                    })
                  "
                  :error-message="
                    $t('associationdetail.campaignprojectNameInfo', {
                      campaignType: this.getCampaignType.toLowerCase(),
                    })
                  "
                  :info="
                    $t('associationdetail.campaignprojectNameInfo', {
                      campaignType: this.getCampaignType.toLowerCase(),
                    })
                  "
                  :value="name"
                  @input="name = $event.target.value"
                ></f7-list-input>
                <f7-block-title class="item-label">{{
                  $t("associationdetail.campainprojectDescription", {
                    campaignType: this.getCampaignType,
                  })
                }}</f7-block-title>
                <f7-text-editor
                  :value="description"
                  name="description"
                  :placeholder="
                    $t('associationdetail.campainprojectDescription', {
                      campaignType: this.getCampaignType,
                    })
                  "
                  @texteditor:change="(v) => (description = v)"
                  resizable
                  required
                  validate
                  outline
                  type="texteditor"
                  :mode="
                    f7.device.cordova || !this.isTablet ? 'keyboard-toolbar' : 'toolbar'
                  "
                  :style="
                    f7.device.cordova || !this.isTablet
                      ? '--f7-text-editor-height: 150px'
                      : ''
                  "
                />
                <f7-list-input
                  :label="$t('associationdetail.selectCampaignBeneficiary')"
                  type="select"
                  :placeholder="$t('associationdetail.selectCampaignBeneficiary')"
                  @input="campaignBeneficiary = $event.target.value"
                  outline
                >
                  <option value="">
                    {{ $t("associationdetail.noBeneficiary") }}
                  </option>
                  <option
                    v-for="member in this.association.associationMemberList"
                    :key="member.id"
                    :value="member.userId"
                  >
                    {{ member.name }}
                  </option>
                </f7-list-input>
                <f7-list-item
                  title="Hide campaign from"
                  smart-select
                  :smart-select-params="{
                    openIn: this.isTablet ? 'popover' : 'sheet',
                  }"
                  outline
                  :footer="$t('associationdetail.hideCampaignNote')"
                >
                  <select name="hideCampaign" multiple v-model="hiddenFrom">
                    <option
                      v-for="member in this.association.associationMemberList"
                      :key="member.id"
                      :value="member.userId"
                    >
                      {{ member.name }}
                    </option>
                  </select>
                </f7-list-item>
                <f7-list-input
                  :label="$t('associationdetail.selectMyCampaignDateRange')"
                  type="datepicker"
                  floating-label
                  outline
                  :placeholder="$t('associationdetail.selectMyCampaignDateRange')"
                  readonly
                  :value="campaignPeriod"
                  @calendar:change="(value) => (campaignPeriod = value)"
                  :calendar-params="{
                    rangePicker: true,
                    closeOnSelect: true,
                    openIn: 'auto',
                    minDate: new Date(),
                    header: true,
                    footer: true,
                  }"
                  :error-message="
                    $t('associationdetail.selectMyCampaignDateRange', {
                      campaignType: this.getCampaignType.toLowerCase(),
                    })
                  "
                  :info="this.campaignDays"
                  clear-button
                ></f7-list-input>
                <f7-list-item
                  v-f="this.campaignBeneficiary === null"
                  :title="$t('common.selectCountry')"
                  outline
                  class="selectCountry item-header"
                  smart-select
                  :smart-select-params="{
                    openIn: 'popup',
                    searchbar: true,
                    searchbarPlaceholder: $t('common.findCountry'),
                    closeOnSelect: true,
                    sheetSwipeToClose: true,
                    view: 'view-main',
                  }"
                >
                  <template #media v-if="this.campaignCountry">
                    <img
                      :src="
                        'https://flagcdn.com/' +
                        this.campaignCountry.countryCode2.toLowerCase() +
                        '.svg'
                      "
                      width="24"
                    />
                    {{ this.campaignCountry.countryCode2 }}
                    +{{ this.campaignCountry.phoneCode }}
                  </template>
                  <select name="country" v-model="selectedCountry">
                    <optgroup
                      class="list-group"
                      v-for="(countryList, continentName, index) in this.countries"
                      :label="continentName"
                      :key="index"
                    >
                      <option
                        v-for="country in countryList"
                        :key="country.id"
                        :value="country.countryCode2"
                        :selected="country.countryCode2 === selectedCountry"
                        :data-option-image="
                          'https://flagcdn.com/' +
                          country.countryCode2.toLowerCase() +
                          '.svg'
                        "
                        width="24"
                      >
                        {{ country.name }} {{ country.countryCode2 }}
                        {{ country.currencyCode }} {{ country.currencyName }}
                      </option>
                    </optgroup>
                  </select>
                </f7-list-item>
                <f7-list-input
                  :label="$t('associationdetail.campaignprojectAmountoptional')"
                  floating-label
                  type="number"
                  outline
                  min="0"
                  :value="campaignAmount"
                  @input="campaignAmount = $event.target.value"
                  pattern="[0-9]*"
                  clear-button
                >
                  <template #media v-if="this.campaignCountry">
                    {{ this.campaignCountry.currencyCode }}
                  </template>
                </f7-list-input>

                <f7-list-input
                  :label="$t('associationdetail.fundingTargetoptional')"
                  floating-label
                  type="number"
                  outline
                  name="fundingTarget"
                  :placeholder="$t('associationdetail.fundingTargetoptional')"
                  min="0"
                  :value="fundingTarget"
                  @input="fundingTarget = $event.target.value"
                  pattern="[0-9]*"
                  clear-button
                >
                  <template #media v-if="this.campaignCountry">
                    {{ this.campaignCountry.currencyCode }}
                  </template>
                </f7-list-input>
                <f7-list simple-list strong outline-ios dividers-ios>
                  <f7-list-item v-if="this.campaignBeneficiary === ''">
                    <span>{{ $t("common.createNewWallet") }}</span>
                    <f7-toggle
                      v-model:checked="this.setNewWallet"
                      color="auto"
                    ></f7-toggle>
                  </f7-list-item>
                </f7-list>
                <f7-list-input
                  v-if="
                    this.association.associationMemberList &&
                    this.association.associationMemberList.length >= 2
                  "
                  :label="$t('associationdetail.selectCampaignprojectManager')"
                  type="select"
                  :placeholder="$t('associationdetail.selectCampaignprojectManager')"
                  outline
                  @input="campaignManager = $event.target.value"
                >
                  <option value="">
                    {{ $t("associationdetail.selectCampaignprojectManager") }}
                  </option>
                  <option
                    v-for="member in this.association.associationMemberList"
                    :key="member.id"
                    :value="member.userId"
                  >
                    {{ member.name }}
                  </option>
                </f7-list-input>
              </f7-list-group>
            </f7-list>

            <f7-toolbar
              tabbar
              bottom
              bg-color="red"
              :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
            >
              <f7-link
                raised
                fill
                large
                text-color="white"
                class="link buttons"
                strong
                @click="addCampaign()"
                >{{
                  $t("associationdetail.addNewCampaignproject", {
                    campaignType: getCampaignType.toLowerCase(),
                  })
                }}</f7-link
              >
            </f7-toolbar>
          </div>
          <div class="right_layout"></div>
        </div>
      </f7-page>
    </f7-view>
  </f7-popup>
  <f7-popup
    class="addNewMember"
    style="min-height: 50%; --f7-popup-bg-color: #fff"
    :tablet-fullscreen="true"
    :backdrop="false"
    :close-by-backdrop-click="false"
    :close-on-escape="false"
  >
    <f7-page>
      <f7-navbar no-shadow>
        <div class="left"></div>
        <div class="title">
          {{ $t("associationdetail.addNewMember") }}
        </div>
        <div class="right">
          <f7-link popup-close icon-f7="xmark_circle"></f7-link>
        </div>
      </f7-navbar>
      <div class="layout_content">
        <div class="left_layout"></div>
        <div class="main_layout">
          <f7-block-title large>{{
            $t("associationdetail.addNewMember")
          }}</f7-block-title>
          <userslist> </userslist>
          <f7-card outline header-divider>
            <f7-card-header>
              {{ $t("tontinedetail.participants") }}
            </f7-card-header>
            <f7-card-content>
              <membersComponent
                :entityId="this.association.id"
                :memberList="this.association.associationMemberList"
                :adminList="association.associationAdminList"
                :entityType="'asso'"
                :isAdmin="currentMemberIsAdmin"
                :mainUserId="association.mainAdminUserId"
              ></membersComponent>
            </f7-card-content>
          </f7-card>

          <f7-toolbar
            tabbar
            bottom
            :style="{ bottom: 0, position: isTablet ? 'initial' : 'fixed' }"
            bg-color="red"
            ripple
            class="link"
            v-if="
              this.searchedUser &&
              this.searchedUser.userId !== this.profile.sub &&
              !this.association.associationMemberList.find(
                (x) => x.userId === this.searchedUser.userId
              )
            "
          >
            <f7-link
              raised
              fill
              large
              ripple
              text-color="white"
              strong
              class="link"
              @click="addMember(searchedUser)"
              >{{
                $t("associationdetail.addAsMember", {
                  addMmember: this.searchedUser.name,
                })
              }}</f7-link
            >
          </f7-toolbar>
        </div>
        <div class="right_layout"></div>
      </div>
    </f7-page>
  </f7-popup>
</template>
<style scoped></style>
<script>
import {
  f7Popover,
  f7Block,
  f7Button,
  f7Link,
  f7Sheet,
  f7PageContent,
  f7,
  theme,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import { association } from "../js/mixins/association.js";
import UsersPage from "../pages/users.vue";
import membersComponent from "../components/modules/members.vue";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  mixins: [association],
  components: {
    userslist: UsersPage,
    membersComponent,
    f7Popover,
    f7Block,

    f7Button,
    f7Link,
    f7Sheet,
    f7PageContent,
  },
  data() {
    return {
      theme,
      f7,
      description: "",
      fundingTarget: "",
      campaignAmount: "",
      campaignPeriod: [],
      campaignManager: "",
      hiddenFrom: [],
      campaignBeneficiary: "",
      name: "",
      description: "",
      selectedCountry: "",
      setNewWallet: true,
    };
  },
  mounted() {
    if (this.association !== null) {
      this.selectedCountry = this.association.countryCode;
    }
    this.campaignManager = this.profile.sub;
  },
  methods: {
    async addCampaign() {
      if (f7.input.validateInputs("#addNewCampaign")) {
        await this.$store.dispatch("asso/createCampaign", {
          memberId: this.campaignManager,
          associationId: this.association.id,
          campaign: {
            campaignNumber:
              parseFloat(this.association.associationCampaignList.length) + 1,
            campaignAmount: this.campaignAmount,
            startDate: this.campaignPeriod[0],
            endDate: this.campaignPeriod[1],
            fundingTarget: this.fundingTarget,
            description: this.description,
            campaignBeneficiary: this.campaignBeneficiary,
            hiddenFrom: this.hiddenFrom,
            name: this.name,
            campaignManager: this.campaignManager,
            countryCode: this.selectedCountry,
            currencyCode: this.campaignCountry.currencyCode,
            walletId: this.setNewWallet ? "true" : "",
          },
        });
        f7.sheet.close(".addNewCampaign");
      }
    },
    async addMember(searchedUser) {
      await this.$store.dispatch("asso/addMember", {
        associationId: this.association.id,
        userId: searchedUser.userId,
      });
      f7.popup.close(".addNewMember");
    },
  },

  computed: {
    ...mapState("asso", [
      "association",
      "campaign",
      "member",
      "associationList",
      "memberList",
      "associationTransaction",
      "associationTransactionList",
    ]),
    ...mapState("account", [
      "primaryAccount",
      "businessAccount",
      "userDetail",
      "searchedUser",
    ]),
    ...mapState("location", [
      "country",
      "apiCode",
      "currentUserCountry",
      "emoneyApiList",
    ]),
    ...mapState("auth", ["video", "isDeskTop", "isTablet", "device", "profile"]),
    countries() {
      return _.groupBy(this.country, "continentName");
    },
    campaignCountry() {
      if (this.association) {
        const bnf = this.association.associationMemberList.find(
          (m) => m.userId === this.campaignBeneficiary
        );
        if (this.campaignBeneficiary === null) {
          this.selectedCountry = this.association.countryCode;
        } else if (this.campaignBeneficiary === null) {
          this.selectedCountry = this.userDetail.countryCode;
        } else if (bnf != undefined) {
          console.log("bnf", bnf);
          this.selectedCountry = bnf.countryCode;
        }
        return this.country.find((c) => c.countryCode2 === this.selectedCountry);
      }
    },
  },
};
</script>
