import auth from "./modules/auth.js";
import account from "./modules/account.js";
import document from "./modules/document.js";
import asso from "./modules/asso.js";
import card from "./modules/card.js";
import delivery from "./modules/delivery.js";
import location from "./modules/location.js";
import message from "./modules/message.js";
import notification from "./modules/notification.js";
import post from "./modules/post.js";
import payment from "./modules/payment.js";
import product from "./modules/product.js";
import remit from "./modules/remit.js";
import shop from "./modules/shop.js";
import shoppingCart from "./modules/shoppingCart.js";
import tontine from "./modules/tontine.js";
import transaction from "./modules/transaction.js";
import wallet from "./modules/wallet.js";
import airtime from "./modules/airtime.js";
import apimoney from "./modules/apimoney.js";
import savings from "./modules/savings.js";
import groupBuy from "./modules/groupBuy.js";
import stripe from "./modules/stripe.js";
import { createStore as createVuexStore } from "vuex";
// import SecureLS from "secure-ls";
// const storage_pwd = import.meta.env.VITE_APP_STR_PWD;
// import FingerprintJS from "@fingerprintjs/fingerprintjs";
// const fpPromise = FingerprintJS.load();
// let fingerprint = "";
// (async () => {
//   // Get the visitor identifier when you need it.
//   const fp = await fpPromise;
//   const result = await fp.get();
//   console.log(result.visitorId);
//   fingerprint = result.visitorId;
// })();
// import createPersistedState from "vuex-persistedstate";
// const ls = new SecureLS({
//   encodingType: "aes",
//   isCompression: true,
//   encryptionSecret: `${storage_pwd}${fingerprint}`,
// });
// import { createStore  } from 'vuex'

import _ from "lodash";
export const createStore = (app) => {
  return createVuexStore({
    // import modules from "./modules/index.js";
    // console.log(modules);
    // export default createStore({
    plugins:
      // process.env.NODE_ENV === "production"
      //   ? [
      //       createPersistedState({
      //         key: "avocash",
      //         storage: {
      //           getItem: (key) => ls.get(key),
      //           setItem: (key, value) => ls.set(key, value),
      //           removeItem: (key) => ls.remove(key),
      //         },
      //       }),
      //     ]
      //   :
      [],
    modules: {
      auth: auth(app),
      account: account(app),
      document: document(app),
      asso: asso(app),
      card: card(app),
      delivery: delivery(app),
      location: location(app),
      message: message(app),
      post: post(app),
      notification: notification(app),
      payment: payment(app),
      product: product(app),
      remit: remit(app),
      shop: shop(app),
      savings: savings(app),
      airtime: airtime(app),
      shoppingCart: shoppingCart(app),
      tontine: tontine(app),
      transaction: transaction(app),
      wallet: wallet(app),
      apimoney: apimoney(app),
      groupBuy: groupBuy(app),
      stripe: stripe(app),
    },
    // https://github.com/robinvdvleuten/vuex-persistedstate#api
  });
};

// const store = createStore({
//     modules: {
//         auth,
//         account,
//         asso,
//         card,
//         delivery,
//         location,
//         message,
//         payment,
//         product,
//         remit,
//         shop,
//         shoppingCart,
//         tontine,
//         transaction,
//         wallet,
//         airtime,
//         savings,
//         apimoney,
//     },
//     // https://github.com/robinvdvleuten/vuex-persistedstate#api
//     // plugins: [
//     //     createPersistedState({
//     //         storage: {
//     //             getItem: (key) => ls.get(key),
//     //             setItem: (key, value) => ls.set(key, value),
//     //             removeItem: (key) => ls.remove(key),
//     //         },
//     //     }),
//     // ],
// });

// export default store;
