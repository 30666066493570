<template>
  <f7-page
    hide-bars-on-scroll
    :page-content="false"
    @page:afterout="afterOut()"
    v-if="this.tontine && this.currentParticipant"
  >
    <!-- :no-toolbar="this.isTablet == true ? false : true" -->
    <f7-navbar sliding no-shadow>
      <f7-nav-left
        :back-link="$t('common.backButton')"
        :back-link-show-text="false"
      ></f7-nav-left>
      <f7-nav-title> {{ this.tontine.name }}</f7-nav-title>
      <f7-nav-right v-if="this.$keycloak.authenticated && this.currentParticipantIsAdmin">
        <f7-link
          icon-aurora="f7:person_crop_circle_fill_badge_plus"
          icon-ios="f7:person_crop_circle_fill_badge_plus"
          icon-md="material:person_add"
          color="red"
          href="#"
          popup-open=".addNewParticipant"
        ></f7-link>
        <f7-link
          icon-aurora="f7:pencil"
          icon-ios="f7:pencil"
          icon-md="material:edit"
          :href="'/tontine/edit/' + this.tontine.id"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-toolbar
      tabbar
      labels
      top
      :scrollable="this.tontine.tontineRoundList.length > 6 ? true : false"
      no-shadow
      class="tontineToolbar"
    >
      <f7-link
        v-if="this.tontine"
        tab-link="#mainPage"
        tab-link-active
        :text="isTablet ? this.tontine.name : ''"
      >
        <img
          v-if="this.tontine.logo !== null"
          :src="`${this.tontine.logo}`"
          width="40"
          height="40"
          style="border-radius: 50%"
          class="margin-left-half"
        />
        <f7-badge
          v-else
          slot="media"
          color="auto"
          class="margin-left-half"
          style="height: 27px; width: 27px; min-width: 27px; border-radius: 50%"
        >
          {{ $filtersGetInitial.getInitial(tontine.name) }}</f7-badge
        >
      </f7-link>

      <f7-link
        v-for="(round, index) in this.tontine.tontineRoundList"
        :key="index + 1"
        v-if="tontine && tontine.tontineRoundList && tontine.tontineRoundList.length > 0"
        :tab-link="`#tab-${round.roundNumber}`"
        @tab:show="selectRound(round)"
        :text="
          isTablet
            ? tontine.tontineParticipantList.find((x) => x.id === round.roundTaker).name
            : tontine.tontineParticipantList.find((x) => x.id === round.roundTaker).name
        "
      >
        <f7-badge
          slot="media"
          color="auto"
          class="margin-left-half"
          style="height: 27px; width: 27px; min-width: 27px; border-radius: 50%"
          >{{ round.roundNumber }}</f7-badge
        >
      </f7-link>
    </f7-toolbar>

    <f7-tabs swipeable class="detailTabs">
      <f7-tab
        id="mainPage"
        style="padding-bottom: 120px !important"
        class="page-content margin-bottom padding-bottom"
        tab-active
      >
        <div class="layout_content">
          <div class="left_layout">
            <div class="sticky with_content">
              <f7-card
                header-divider
                outline
                v-if="
                  this.tontine && this.$keycloak.authenticated && this.currentParticipant
                "
              >
                <f7-card-header>
                  {{ $t("tontinedetail.participants") }}
                  <f7-badge
                    v-if="currentParticipantIsAdmin"
                    bg-color="red"
                    class="addParticipantToolTip"
                  >
                    <f7-link
                      icon-ios="f7:person_crop_circle_fill_badge_plus"
                      icon-md="material:person_add"
                      color="white"
                      href="#"
                      popup-open=".addNewParticipant"
                    ></f7-link>
                  </f7-badge>
                </f7-card-header>
                <membersComponent
                  :entityId="this.tontine.id"
                  :memberList="this.tontine.tontineParticipantList"
                  :adminList="this.tontine.tontineAdminList"
                  :entityType="'tontine'"
                  :isAdmin="currentParticipantIsAdmin"
                  :mainUserId="tontine.mainAdminUserId"
                ></membersComponent>
              </f7-card>
            </div>
          </div>
          <div class="main_layout">
            <f7-card class="demo-facebook-card">
              <f7-card-header class="no-border">
                <div class="demo-facebook-avatar padding-right">
                  <img
                    v-if="this.tontine.logo !== null"
                    :src="`${this.tontine.logo}`"
                    width="40"
                    height="40"
                    style="border-radius: 50%"
                  />
                  <f7-badge
                    v-else
                    slot="media"
                    color="auto"
                    style="height: 40px; width: 40px; font-size: 16px; border-radius: 50%"
                  >
                    {{ $filtersGetInitial.getInitial(tontine.name) }}</f7-badge
                  >
                </div>
                <div class="demo-facebook-name">
                  {{ this.tontine.name }}
                </div>
                <div class="demo-facebook-date" v-html="this.tontine.tag"></div>
              </f7-card-header>
              <f7-card-content :padding="false" v-if="this.tontine">
                <swiper-container
                  v-if="this.tontinePhotos"
                  :pagination="this.isTablet ? false : true"
                  :slidesPerView="this.isTablet ? '1' : '1'"
                  :spaceBetween="0"
                  :disableOnInteraction="true"
                >
                  <f7-photo-browser
                    :photos="this.tontinePhotos"
                    ref="popup"
                  ></f7-photo-browser>
                  <swiper-slide
                    :style="{
                      background:
                        'linear-gradient(90deg, rgba(131,58,180,1) 0%, rgba(29,253,36,1) 50%, rgba(252,176,69,1) 100%)',
                      height: '400px',
                    }"
                  >
                    <ul ref="carousel" class="circle-container">
                      <li
                        class="elevation-5"
                        v-for="(round, index) in this.tontine.tontineRoundList"
                        :key="index"
                      >
                        <a
                          href="#"
                          class="item-link item-content"
                          @click="selectRound(round)"
                        >
                          <div class="item-inner">
                            <div
                              class="item-title participantName"
                              v-if="round.roundTaker !== null"
                            >
                              {{
                                tontine.tontineParticipantList.find(
                                  (x) => x.id === round.roundTaker
                                ).name
                              }}
                            </div>
                            <div class="item-after round">
                              {{ $t("tontinedetail.rd") }}{{ round.roundNumber }}
                            </div>
                          </div>
                        </a>
                      </li>
                      <li
                        class="elevation-5"
                        color="white"
                        href="#"
                        style="background-color: white"
                      >
                        <div
                          class="item-inner"
                          @click="addNewRoundPopup()"
                          v-if="currentParticipantIsAdmin"
                        >
                          <div
                            :class="
                              'item-media' + theme.ios
                                ? 'roundAddIconIOS'
                                : 'roundAddIconMD'
                            "
                          >
                            <f7-icon
                              color="auto"
                              ios="f7:plus"
                              md="material:add"
                            ></f7-icon>
                          </div>
                          <div class="item-after round">
                            {{ $t("tontinedetail.rd")
                            }}{{ tontine.tontineRoundList.length + 1 }}
                          </div>
                        </div>
                        <div class="item-inner" v-else>
                          <div
                            :class="
                              'item-media' + theme.ios
                                ? 'roundAddIconIOS'
                                : 'roundAddIconMD'
                            "
                          >
                            <f7-icon
                              color="gray"
                              ios="f7:plus"
                              md="material:add"
                            ></f7-icon>
                          </div>
                          <div class="item-after round">
                            {{ $t("tontinedetail.rd")
                            }}{{ tontine.tontineRoundList.length + 1 }}
                          </div>
                        </div>
                      </li>
                      <li
                        readonly
                        class="elevation-3"
                        v-for="(n, key) in 10 - (tontine.tontineRoundList.length + 1)"
                        :key="key + tontine.tontineRoundList.length + 1"
                        style="background-color: #f2f3f4"
                      >
                        <div class="item-inner">
                          <div
                            :class="
                              'item-media' + theme.ios
                                ? 'roundAddIconIOS'
                                : 'roundAddIconMD'
                            "
                          >
                            <f7-icon
                              color="black"
                              ios="f7:plus"
                              md="material:add"
                            ></f7-icon>
                          </div>
                          <div class="item-after round">
                            {{ $t("tontinedetail.rd")
                            }}{{ n + tontine.tontineRoundList.length + 1 }}
                          </div>
                        </div>
                      </li>
                      <li class="center align-content-center">
                        <p style="font-size: 13px" class="align-content-center">
                          {{ $t("tontinedetail.collected") }}:
                          <span
                            style="font-weight: bold; font-size: 16px"
                            v-html="
                              $filtersToCurrency.toCurrency(
                                (this.tontine.collectedAmount || 0).toFixed(2),
                                null,
                                this.tontine.currencyCode
                              )
                            "
                          ></span>
                          <br />{{ $t("common.balance")
                          }}<span
                            style="font-weight: bold; font-size: 16px"
                            v-html="
                              $filtersToCurrency.toCurrency(
                                (this.tontine.accountBalance || 0).toFixed(2),
                                null,
                                this.tontine.currencyCode
                              )
                            "
                          >
                          </span>
                          <br />{{ $t("tontinedetail.round") }}:

                          <span
                            v-html="
                              $filtersToCurrency.toCurrency(
                                this.tontine.tontineRoundAmount,
                                null,
                                this.tontine.currencyCode
                              )
                            "
                          ></span
                          ><br />{{
                            $t("tontinedetail.frequency", {
                              frequency:
                                this.tontine.frequency + " " + this.tontine.frequencyType,
                            })
                          }}
                        </p>
                      </li>
                    </ul>
                  </swiper-slide>
                  <swiper-slide
                    v-for="(imageUrl, index) in this.tontinePhotos"
                    :key="index"
                    style="height: 400px"
                  >
                    <a
                      link="#"
                      @click="$refs.popup.open()"
                      style="display: flex; align-items: center; justify-content: center"
                    >
                      <img
                        :src="imageUrl.url"
                        v-if="imageUrl.fileType === 'image'"
                        :style="{
                          display: 'block',
                          margin: 'auto',
                          height: 'auto',
                          position: 'absolute',
                          top: 0,
                          bottom: 0,
                          width: '100%',
                        }"
                        :alt="imageUrl.fileName"
                      />
                      <video
                        v-if="imageUrl.fileType === 'video'"
                        :src="imageUrl.url"
                        controls
                        :alt="imageUrl.fileName"
                      />
                    </a>
                  </swiper-slide>
                </swiper-container>
              </f7-card-content>
              <f7-button
                v-if="
                  this.tontine.tontineRoundList &&
                  this.tontine.tontineRoundList.length > 0
                "
                raised
                fill
                large
                @click="goToContributeNoRound()"
                style="border-radius: 0px"
                class="no-margin-horiztontal contributeButton buttons"
                color="red"
                >{{ $t("tontinedetail.contribute") }}</f7-button
              >
              <f7-button
                v-else
                raised
                fill
                large
                popup-open=".addNewRound"
                style="border-radius: 0px"
                class="no-margin-horiztontal contributeButton buttons"
                color="red"
                >{{ $t("tontinedetail.addNewRound") }}</f7-button
              >
            </f7-card>

            <f7-list
              dividers-ios
              strong-ios
              accordion-list
              media-list
              class="margin-top-half"
            >
              <li class="item-content">
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title" style="color: gray">
                      {{ $t("tontinedetail.roundAmount") }}
                    </div>
                    <div class="item-title" style="color: gray">
                      {{ $t("tontinedetail.roundFrequency") }}
                    </div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title" style="font-weight: bold; color: green">
                      <span
                        v-html="
                          $filtersToCurrency.toCurrency(
                            this.tontine.tontineRoundAmount,
                            null,
                            this.tontine.currencyCode
                          )
                        "
                      ></span>
                    </div>
                    <div class="item-title" style="font-weight: bold; color: auto">
                      {{ this.tontine.frequency + " " + this.tontine.frequencyType }}
                    </div>
                  </div>
                </div>
              </li>
              <li class="item-content">
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title" style="color: gray">
                      {{ $t("common.collected") }}:
                    </div>
                    <div class="item-title" style="color: gray">
                      {{ $t("common.balance") }}
                    </div>
                  </div>
                  <div class="item-title-row">
                    <div class="item-title" style="font-weight: bold; color: green">
                      <span
                        v-html="
                          $filtersToCurrency.toCurrency(
                            (this.tontine.collectedAmount || 0).toFixed(2),
                            null,
                            this.tontine.currencyCode
                          )
                        "
                      ></span>
                    </div>
                    <div class="item-title" style="font-weight: bold; color: auto">
                      <span
                        v-html="
                          $filtersToCurrency.toCurrency(
                            (this.tontine.accountBalance || 0).toFixed(2),
                            null,
                            this.tontine.currencyCode
                          )
                        "
                      ></span>
                    </div>
                  </div>
                </div>
              </li>

              <f7-list-item accordion-item :title="$t('common.description')">
                <f7-accordion-content>
                  <f7-block>
                    <p v-html="this.tontine.description"></p>
                  </f7-block>
                </f7-accordion-content>
              </f7-list-item>
            </f7-list>
            <div class="right not_sticky_with_content">
              <campaignRounds
                :entity="this.tontine"
                :entityType="'tontine'"
              ></campaignRounds>
            </div>
            <f7-card
              class="left not_sticky_with_content"
              header-divider
              outline
              v-if="
                this.tontine && this.$keycloak.authenticated && this.currentParticipant
              "
            >
              <f7-card-header>
                {{ $t("tontinedetail.participants") }}
                <f7-badge
                  v-if="currentParticipantIsAdmin"
                  bg-color="red"
                  class="addParticipantToolTip"
                >
                  <f7-link
                    icon-ios="f7:person_crop_circle_fill_badge_plus"
                    icon-md="material:person_add"
                    color="white"
                    href="#"
                    popup-open=".addNewParticipant"
                  ></f7-link>
                </f7-badge>
              </f7-card-header>

              <membersComponent
                :entityId="this.tontine.id"
                :memberList="this.tontine.tontineParticipantList"
                :adminList="this.tontine.tontineAdminList"
                :entityType="'tontine'"
                :isAdmin="currentParticipantIsAdmin"
                :mainUserId="tontine.mainAdminUserId"
              ></membersComponent>
            </f7-card>
          </div>
          <div class="right_layout">
            <div class="sticky with_content">
              <campaignRounds
                :entity="this.tontine"
                :entityType="'tontine'"
              ></campaignRounds>
            </div>
          </div>
        </div>
      </f7-tab>

      <f7-tab
        style="padding-bottom: 100px !important"
        class="page-content margin-bottom padding-bottom"
        v-for="(round, index) in this.tontine.tontineRoundList"
        :key="index + 1"
        :id="`tab-${round.roundNumber}`"
      >
        <div class="layout_content">
          <div class="left_layout">
            <div outline class="sticky with_content"></div>
          </div>
          <div class="main_layout">
            <f7-card
              class="demo-facebook-card"
              :id="`roundView-${round.roundNumber}`"
              header-divider
            >
              <f7-card-header
                class="no-border"
                v-if="
                  round.roundTaker !== null &&
                  tontine.tontineParticipantList.find((x) => x.id === round.roundTaker)
                    .userId === profile.sub
                "
                bg-color="green"
                color="white"
                text-color="auto"
              >
                <div class="demo-facebook-avatar padding-right">
                  {{ $t("tontinedetail.rd") }}
                  <f7-badge
                    width="50px"
                    height="50px"
                    style="
                      height: 32px;
                      width: 32px;
                      font-size: 16px;
                      background-color: #0009ff;
                      color: white;
                    "
                    >{{ round.roundNumber }}</f7-badge
                  >
                </div>
                <div
                  style="white-space: normal; word-wrap: break-word; font-weight: 90%"
                  class="demo-facebook-name"
                >
                  {{
                    tontine.tontineParticipantList.find((x) => x.id === round.roundTaker)
                      .name
                  }}
                </div>
              </f7-card-header>
              <f7-card-header
                class="no-border"
                v-if="
                  round.roundTaker !== null &&
                  tontine.tontineParticipantList.find((x) => x.id === round.roundTaker)
                    .userId !== profile.sub
                "
              >
                <div class="demo-facebook-avatar padding-right">
                  {{ $t("tontinedetail.rd") }}
                  <f7-badge
                    class="elevation-3 no-border"
                    width="50px"
                    height="50px"
                    color="auto"
                    style="height: 32px; width: 32px; font-size: 16px"
                    >{{ round.roundNumber }}</f7-badge
                  >
                </div>
                <div
                  style="white-space: normal; word-wrap: break-word; font-weight: 90%"
                  class="demo-facebook-name"
                >
                  {{
                    tontine.tontineParticipantList.find((x) => x.id === round.roundTaker)
                      .name
                  }}
                </div>
                <div class="demo-facebook-date">
                  {{
                    tontine.tontineParticipantList.find((x) => x.id === round.roundTaker)
                      .phoneNumber
                  }}
                </div>
              </f7-card-header>
              <f7-card-header
                class="no-border"
                v-if="round.roundTaker === null"
                href="#"
                @click="openParticipantList(round)"
              >
                <f7-link
                  @click="openParticipantList(round)"
                  :text="$t('tontinedetail.setRoundTaker')"
                ></f7-link>
                <div
                  :style="{
                    float: 'right',
                    position: 'absolute',
                    right: '16px',
                    top: '15px',
                  }"
                >
                  {{ $t("tontinedetail.rd") }}
                  <f7-badge
                    class="elevation-3 no-border"
                    width="50px"
                    height="50px"
                    color="auto"
                    style="height: 32px; width: 32px; font-size: 16px"
                    >{{ round.roundNumber }}</f7-badge
                  >
                </div>
              </f7-card-header>
              <f7-card-content>
                <div class="grid small-grid-cols-2 xsmall-grid-cols-1">
                  <span>
                    <small :style="{ opacity: 0.7 }">{{
                      $t("tontinedetail.roundAmount")
                    }}</small>
                    <span
                      style="font-weigth: bold; margin-right: 16px"
                      v-html="
                        $filtersToCurrency.toCurrency(
                          (round.tontineRoundAmount || 0).toFixed(2),
                          null,
                          round.currencyCode
                        )
                      "
                    ></span>
                    <br />

                    <small :style="{ opacity: 0.7 }">{{ $t("common.balance") }}</small>
                    <span
                      style="font-weigth: bold; margin-right: 8px"
                      v-html="
                        $filtersToCurrency.toCurrency(
                          (round.accountBalance || 0).toFixed(2),
                          null,
                          round.currencyCode
                        )
                      "
                    ></span>
                  </span>
                  <f7-gauge
                    v-if="tontine && tontine.tontineParticipantList.length > 0"
                    type="circle"
                    :value="
                      parseFloat(round.collectedAmount) /
                      parseFloat(
                        round.tontineRoundAmount * tontine.tontineParticipantList.length
                      )
                    "
                    :labelText="$t('common.collected')"
                    :valueText="
                      $filtersToCurrency.toCurrency(
                        (round.collectedAmount || 0).toFixed(2),
                        null,
                        round.currencyCode
                      )
                    "
                    valueTextColor="auto"
                    border-color="green"
                    labelTextColor="#4caf50"
                    :labelFontWeight="600"
                    :labelFontSize="12"
                    :borderWidth="30"
                    bg-color="white"
                  />
                </div>

                <f7-list
                  dividers-ios
                  strong-ios
                  media-list
                  v-if="tontine && isAuthenticated && currentParticipant"
                  class="margin-top"
                >
                  <f7-list-item
                    v-if="round.roundTaker !== null"
                    :header="$t('tontinedetail.roundTaker')"
                  >
                    {{ getRoundTaker(round.roundTaker) }}</f7-list-item
                  >
                  <f7-list-item
                    v-else
                    @click="openParticipantList(round)"
                    href="#"
                    :title="$t('tontinedetail.setRoundTaker')"
                  >
                  </f7-list-item>
                  <f7-badge
                    v-if="currentParticipantIsAdmin"
                    :style="{
                      position: 'absolute',
                      right: '16px',
                      top: '16px',
                      'border-radius': '50px',
                      height: '32px',
                      width: '32px',
                      'background-color': 'var(--f7-theme-color)',
                    }"
                    :class="`elevation-3 selectChangeRoundTaker${round.id}`"
                  >
                    <f7-link
                      icon-aurora="f7:person_crop_circle_fill_badge_plus"
                      icon-ios="f7:person_crop_circle_fill_badge_plus"
                      icon-md="material:person_add"
                      color="white"
                      href="#"
                      @click="openParticipantList(round)"
                    ></f7-link>
                  </f7-badge>
                </f7-list>
              </f7-card-content>
              <!-- <transactionCharts></transactionCharts> -->
              <div
                class="grid grid-cols-2 grid-gap"
                v-if="round.accountBalance > 0 && currentParticipantIsAdmin"
              >
                <f7-button
                  raised
                  fill
                  large
                  style="border-radius: 0px"
                  class="no-margin-horiztontal contributeButton buttons"
                  @click="goToContribute(round)"
                  color="red"
                  >{{ $t("tontinedetail.contribute") }}</f7-button
                >

                <f7-button
                  raised
                  fill
                  large
                  class="no-margin-horiztontal contributeButton buttons"
                  style="border-radius: 0px"
                  color="red"
                  @click="redeemRound(round)"
                  >{{ $t("tontinedetail.redeemRound") }}</f7-button
                >
              </div>
              <f7-button
                v-else
                raised
                fill
                large
                style="border-radius: 0px"
                class="no-margin-horiztontal contributeButton buttons"
                @click="goToContribute(round)"
                color="red"
                >{{ $t("tontinedetail.contribute") }}</f7-button
              >
            </f7-card>
            <div class="right not_sticky_with_content">
              <getTransactions
                :transactionList="round.tontineTransactionList"
                v-if="round.tontineTransactionList.length > 0"
              ></getTransactions>
            </div>
          </div>
          <div class="right_layout">
            <div class="sticky with_content">
              <getTransactions
                :transactionList="round.tontineTransactionList"
                v-if="round.tontineTransactionList.length > 0"
              ></getTransactions>
            </div>
          </div>
        </div>
      </f7-tab>
    </f7-tabs>
    <addNewCampaign :tontine="this.tontine" />
    <template #fixed>
      <f7-fab
        v-if="
          this.tontine &&
          this.tontine.tontineRoundList.length <
            this.tontine.tontineParticipantList.length &&
          this.currentParticipantIsAdmin
        "
        position="center-bottom"
        :text="'Add round n°' + parseFloat(this.tontine.tontineRoundList.length + 1)"
        color="red"
        @click="addNewRoundPopup()"
      >
        <f7-icon ios="f7:plus" aurora="f7:plus" md="material:add"></f7-icon>
      </f7-fab>
      <f7-fab position="left-bottom" color="auto" @click="goToMessageGroup()">
        <f7-icon
          ios="f7:ellipses_bubble_fill"
          aurora="f7:ellipses_bubble_fill"
          md="material:chat"
        ></f7-icon>
      </f7-fab>
      <f7-fab
        position="right-bottom"
        class="shareButton"
        color="auto"
        @click="socialShare(sharedContent)"
      >
        <f7-icon
          aurora="f7:square_arrow_up"
          ios="f7:square_arrow_up"
          md="material:share"
        ></f7-icon>
      </f7-fab>
    </template>
  </f7-page>
</template>
<style lang="scss">
@use "sass:math";

@mixin distribute-on-circle($nb-items, $circle-size, $item-size, $class-for-IE: false) {
  $half-item: math.div($item-size, 2);
  $half-parent: math.div($circle-size, 2);

  position: relative;
  /* 1 */
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;
  /* 2 */
  box-sizing: content-box;
  /* 3 */

  > * {
    /* 4 */
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: -$half-item;
  }

  $angle: math.div(360, $nb-items);
  $rot: 0;

  @for $i from 1 through $nb-items {
    @if not $class-for-IE {
      > :nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg) translate($half-parent) rotate($rot * -1deg);
      }
    } @else {
      > .#{$class-for-IE}#{$i} {
        // If CSS transforms are not supported
        $mt: sin($rot * pi() / 180) * $half-parent - $half-item;
        $ml: cos($rot * pi() / 180) * $half-parent - $half-item;
        margin: $mt 0 0 $ml;
      }
    }

    $rot: ($rot + $angle);
  }
}

.circle-container {
  @include distribute-on-circle(10, 20em, 4em, false);
  margin: 3em auto 0;
}

.circle-container li {
  display: block;
  width: 100%;
  border-radius: 50%;
  // border: solid 0px tomato;
  height: 60px;
  width: 60px;
  // filter: grayscale(100%);

  // &:hover {
  //   filter: grayscale(0);
  // }
  text-align: center;
  align-items: center;
  justify-content: center;
}

.circle-container li i.icon.f7- {
  font-weight: bolder;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 28%;
  // color: blue;
}

.circle-container li i.icon.material- {
  font-weight: bolder;
  text-align: center;
  align-items: center;
  justify-content: center;
  margin-top: 33%;
  // color: blue;
}

.elevation-5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14),
    0px 1px 14px 0px rgba(0, 0, 0, 0.12) !important;
}

.participantName {
  font-size: 12px;
  color: white;
  margin-top: 17%;
}

.roundAddIconMD {
  margin-top: 33%;
}

.roundAddIconIOS {
  margin-top: 28%;
}

.center {
  display: absolute !important;
  width: 100% !important;
  border-radius: 50% !important;
  // border: solid red !important;
  height: 180px !important;
  width: 180px !important;
  color: white;
  margin-left: -33%;
  margin-top: -16%;
  text-align: center;
  display: flex;
  /* or inline-flex */
  align-items: center;
  justify-content: center;
}

.round {
  margin-top: 27%;
  color: white;
}
</style>
<style scoped>
.demo-facebook-card .card-content img {
  display: block;
}

.demo-facebook-card .card-content-padding {
  padding: 15px 10px;
}

.demo-facebook-card .card-content-padding .likes {
  color: #8e8e93;
}
</style>
<style>
.addParticipantToolTip {
  position: absolute;
  right: 16px;
  border-radius: 50px;
  height: 32px;
  width: 32px;
}
</style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavTitle,
  f7NavRight,
  f7Link,
  f7Toolbar,
  f7Badge,
  f7Tabs,
  f7Tab,
  f7Card,
  f7CardHeader,
  f7CardContent,
  f7PhotoBrowser,
  f7Button,
  f7List,
  f7ListItem,
  f7AccordionContent,
  f7Block,
  f7Gauge,
  f7ListGroup,
  f7Input,
  f7ListInput,
  f7Popup,
  f7BlockTitle,
  f7PageContent,
  f7Chip,
  f7Fab,
  f7Icon,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import membersComponent from "../components/modules/members.vue";
import campaignRounds from "../components/modules/campaignRounds.vue";
import addNewCampaign from "../components/tontineAddRound.vue";
import getTransactions from "../components/modules/transactions.vue";
// import transactionCharts from "../components/modules/charts.vue";

import userslist from "./users.vue";
import { calculateTransferPayment } from "../js/mixins/transaction.js";
import { socialShare } from "../js/mixins/cordovaPlugins.js";
import { tontine } from "../js/mixins/tontine.js";
import { contributeRedeem } from "../js/mixins/contributeRedeem.js";

import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    membersComponent,
    campaignRounds,
    getTransactions,
    // transactionCharts,
    navbarright: Navbarright,
    userslist,
    addNewCampaign,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavTitle,
    f7NavRight,
    f7Link,
    f7Toolbar,
    f7Badge,
    f7Tabs,
    f7Tab,
    f7Card,
    f7CardHeader,
    f7CardContent,

    f7PhotoBrowser,

    f7Button,
    f7List,
    f7ListItem,
    f7AccordionContent,
    f7Block,
    f7Gauge,

    f7ListGroup,
    f7Input,
    f7ListInput,
    f7Popup,
    f7BlockTitle,
    f7PageContent,
    f7Chip,
    f7Fab,
    f7Icon,
  },
  mixins: [calculateTransferPayment, socialShare, tontine, contributeRedeem],
  data() {
    return {
      theme,
      f7,
      searchParam: "",
      newRound: "",
      tontineRound: "",
      tontineRoundAmount: "",
      roundTaker: "",
      searchCriteria: "email",
      countryCode: "",
      phoneCode: "",
      amountToPay: "",
      paymentCurrency: "",
      cardNumber: "",
      cardPIN: "",
      nocardNumberOrPINError: "",
      amountToRedeem: "",
      elements: [],
      beneficiaryParticipant: "",
      participantCountry: "",
      roundTakerParticipant: "",
      currentRound: "",
      sharedContent: "",
      contributeButton: "",
      fileToSave: [],
      description: "",
      type: "tontine",
      amount: "",
      setAmount: null,
      walletCampaignBeneficiary: "",
      project_type: "",
      isAdmin: "",
      isMember: "",
      trxType: "",
      referenceId: "",
      receiver_wallet_id: "",
      sender_wallet_id: "",
      tag: "",
      return_url: "",
      return_url_fail: "",
      countryCode: "",
      project: "",
      entity: "",
    };
  },

  mounted() {
    this.paymentCurrency = this.tontine.currencyCode;
    if (!this.userAccount && this.$keycloak.authenticated) {
      this.$store.dispatch("account/loadAccount", this.profile.sub);
    }
    if (this.country === null) {
      this.$store.dispatch("location/getCountry");
    }
    this.amountToPay = this.tontine.tontineRoundAmount;
    if (this.tontine) {
      this.newRound = parseFloat(this.tontine.tontineRoundList.length + 1);
      this.tontineRoundAmount = this.tontine.tontineRoundAmount;
    }
    this.$store.dispatch(
      "tontine/getTontineById",
      this.tontine.id || this.f7route.params.entityId
    );
  },
  methods: {
    goToContributeNoRound() {
      const self = this;

      if (this.tontine.tontineRoundList && this.tontine.tontineRoundList.length == 1) {
        this.goToContribute(this.tontine.tontineRoundList[0]);
        return;
      }
      const butt = [];
      const label = {
        text: self.$t("tontinedetail.selectRoundContribute"),
        // color: "auto",
        bold: true,
      };

      butt.push(label);
      for (const round of this.tontine.tontineRoundList) {
        butt.push({
          text:
            `${self.$t("tontinedetail.round")} ` +
            ' <span class="badge color-auto">' +
            round.roundNumber +
            "</span>",
          onClick() {
            self.goToContribute(round);
          },
        });
      }
      if (!self.contribToPopover) {
        self.contribToPopover = f7.actions.create({
          convertToPopover: true,
          buttons: [
            butt,
            [
              {
                text: self.$t("common.cancel"),
                color: "red",
              },
            ],
          ],
          targetEl: self.$el.querySelector(".contributeButton"),
        });
      }
      self.contribToPopover.open();
    },

    goToContribute(round) {
      this.project_type = "contribute";
      this.preparePayment(round);
      setTimeout(() => {
        this.contribute();
      }, 500);
    },
    redeemRound(round) {
      this.project_type = "redeem";
      this.preparePayment(round);
      setTimeout(() => {
        this.redeem();
      }, 500);
    },
    async goToMessageGroup() {
      f7.preloader.show("multi");
      setTimeout(() => {
        const chatId =
          this.tontine.chatGroupId === "" ? this.tontine.id : this.tontine.chatGroupId;
        this.f7router.navigate(`/message/${chatId}/GROUP`, {
          reloadAll: true,
        });
        f7.preloader.hide();
      }, 2000);
    },

    async validateRedeem(tontineTransaction) {
      f7.preloader.show("multi");
      if (tontineTransaction !== null) {
        console.log(tontineTransaction);
        await this.$store.dispatch("tontine/validateRoundRedeem", {
          transferCode: tontineTransaction.transferCode,
          transferPIN: tontineTransaction.transferPIN,
        });
      }
      await this.$store.dispatch("tontine/getTontineTransactionList", {
        tontineId: this.tontine.id,
        maxNumberOfItem: 25,
      });
      this.validatedDialog();
      setTimeout(() => {
        f7.preloader.hide();
      }, 300);
    },

    validatedDialog() {
      if (!this.toastIcon) {
        this.toastIcon = f7.toast.create({
          icon: theme.ios
            ? '<i class="f7-  text-color-green border-color-gray color-green" style="font-size:150px">checkmark_circle</i>'
            : '<i class="material-  text-color-green " style="font-size:150px">check_circle_outline</i>',
          cssClass: "toast_validate",
          position: "center",
          closeTimeout: 1500,
        });
      }
      this.toastIcon.open();
    },

    addNewRoundPopup() {
      f7.popup.open(".addNewRound");
      this.roundNumber = parseFloat(this.tontine.tontineRoundList.length) + 1;
      this.tontineRoundAmount = this.tontine.tontineRoundAmount;
    },

    afterOut() {
      this.$store.dispatch("tontine/getUserTontinesByuserId", {
        maxNumberOfItem: this.visibleItem,
      });
    },

    getParticipant(roundTaker) {
      const participant = _.find(this.tontine.tontineParticipantList, ["id", roundTaker]);
      if (participant) {
        return participant.name;
      } else {
        return;
      }
    },
    async changeRoundTaker(participant) {
      await this.$store.dispatch("tontine/changeRoundTaker", {
        id: this.currentRound.id,
        roundTaker: participant.id,
      });
    },

    openParticipantList(round) {
      const self = this;

      this.currentRound = round;
      const participants = [];
      const label = {
        text: self.$t("tontinedetail.selectRoundTaker"),
        // color: "auto",
        bold: true,
      };

      participants.push(label);
      for (const participant of this.tontine.tontineParticipantList) {
        participants.push({
          text: participant.name,
          //  +
          // "<br>" +
          // "+" +
          // participant.phoneNumber +
          // "<br>" +
          // participant.email,
          bold: true,
          onClick() {
            self.changeRoundTaker(participant);
          },
        });
      }
      if (!self.roundTakerChangeToPopover) {
        self.roundTakerChangeToPopover = f7.actions.create({
          convertToPopover: true,
          buttons: [
            participants,
            [
              {
                text: self.$t("common.cancel"),
                color: "red",
              },
            ],
          ],
          targetEl: self.$el.querySelector(`.selectChangeRoundTaker${round.id}`),
        });
      }
      self.roundTakerChangeToPopover.open();
    },

    getRoundTaker(value) {
      const participant = _.find(this.tontine.tontineParticipantList, ["id", value]);
      if (participant) {
        return participant.name;
      } else {
        return;
      }
    },
  },

  computed: {
    ...mapState("tontine", [
      "tontine",
      "projectCategories",
      "tontineOwner",
      "tontineList",
      "participantList",
      "tontineTransaction",
      "thirdPartyAllowedWalletList",
      "privateWalletTransactionList",
      "publicWalletTransactionList",
      // "tontineImages",
      // "tontineQRImage",
    ]),

    ...mapState("location", [
      "destinationCity",
      "country",
      "countryStates",
      "countryCities",
      "currentUserCountry",
    ]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),

    ...mapState("auth", [
      "phoneNumberDetail",
      "mobilePhoneError",
      "profile",
      "isDeskTop",
      "isTablet",
      "dynamicLink",
    ]),
    ...mapState("transaction", ["destinationCountry", "originCountry"]),

    ...mapGetters("auth", [
      "getProfile",
      "isAuthenticated",
      "isProfileLoaded",
      "getusererror",
    ]),

    sortedRoundList() {
      return this.tontine.tontineRoundList
        .slice()
        .sort((a, b) => a.roundNumber - b.roundNumber);
    },

    tontinePhotos() {
      this.tontine.tontineImageList?.map((item) => {
        return item.fileType === "video"
          ? {
              html: `<video src="${item.url}" controls ></video>`,
              caption: item.fileName,
            }
          : {
              url: item.url,
              caption: item.fileName,
            };
      });
    },
    currentParticipant() {
      if (this.profile.sub) {
        return (
          _.find(this.tontine.tontineParticipantList, ["userId", this.profile.sub]) !==
          null
        );
      }
    },
    currentParticipantIsAdmin() {
      if (this.profile.sub && this.tontine) {
        return (
          _.find(this.tontine.tontineAdminList, ["userId", this.profile.sub]) !== null
        );
      }
    },

    tontineEndDate() {
      const currentDate = new Date();
      if (this.tontine.frequencyType === "days") {
        return currentDate.setDate(currentDate.getDate() + this.tontine.frequency * 1);
      } else if (this.tontine.frequencyType === "weeks") {
        return currentDate.setDate(currentDate.getDate() + this.tontine.frequency * 7);
      } else if (this.tontine.frequencyType === "months") {
        return currentDate.setDate(currentDate.getDate() + this.tontine.frequency * 30);
      }
    },
    daysLeft() {
      return (
        (new Date(this.tontineEndDate).getTime() - new Date().getTime()) /
        (1000 * 3600 * 24)
      ).toFixed(0);
    },

    selectCriteria() {
      return _.find(this.memberSearchParams, ["value", this.searchCriteria]);
    },
  },
};
</script>
