<template>
  <f7-page
    hide-navbar-on-scroll
    ptr
    :ptr-mousewheel="true"
    @ptr:refresh="loadMore"
    @page:init="pageInit()"
    infinite
    :infinite-distance="50"
    :infinite-preloader="showPreloader"
    @infinite="loadMoreScroll"
  >
    <f7-navbar sliding no-shadow>
      <f7-nav-left
        v-if="this.isTablet"
        :back-link="$t('common.backButton')"
        :back-link-show-text="false"
      ></f7-nav-left>
      <f7-nav-title v-if="this.isTablet" :title="$t('common.notification')">
      </f7-nav-title>
      <f7-searchbar
        class="searchbar-notifications"
        search-container=".notifications-list"
        :backdrop="false"
        :placeholder="$t('common.search')"
        search-in=".item-title, .item-after, .item-subtitle, item-header, .item-text"
        style="max-width: 600px; margin: auto"
      >
      </f7-searchbar>
      <f7-nav-right>
        <f7-link v-if="$keycloak.authenticated">
          <f7-badge
            v-if="this.profile && (this.userAccount || this.userAccount.avatar !== null)"
            slot="media"
            color="primary"
            class="no-padding no-margin"
            :style="{
              height: theme.md ? '32px' : '28px',
              width: theme.md ? '32px' : '28px',
              'min-width': theme.md ? '32px' : '28px',
              'border-radius': '50%',
            }"
            >{{ $filtersGetInitial.getInitial(profile.name) }}</f7-badge
          >
          <img
            :src="`${this.userAccount.avatar}`"
            v-if="this.userAccount && this.userAccount.avatar !== null"
            :style="{
              height: theme.md ? '32px' : '28px',
              width: theme.md ? '32px' : '28px',
              'background-size': 'cover',
              'min-width': theme.md ? '32px' : '28px',
              display: 'block',
              'border-radius': '50%',
            }"
            slot="media"
            alt="user avatar"
          />
        </f7-link>
      </f7-nav-right>
    </f7-navbar>
    <div class="layout_content">
      <div class="left_layout"></div>
      <div class="main_layout">
        <f7-list
          dividers
          strong-ios
          transaction-list
          class="searchbar-not-found"
          v-if="this.notificationList && this.notificationList.length === 0"
        >
          <f7-list-item :title="$t('common.nothingFound')"> </f7-list-item>
        </f7-list>
        <f7-list
          v-else
          media-list
          strong-ios
          dividers-ios
          outline-ios
          class="notifications-list searchbar-found"
        >
          <f7-list-item
            swipeout
            v-for="(item, index) in this.notificationList"
            :key="index"
            v-if="
              item &&
              item !== null &&
              !item.has_deleted.find((deleted) => deleted === profile.sub)
            "
            :title="item.subject"
            :subtitle="item.senderName"
            :text="item.body"
            :after="this.$dayjs(item.sent_time).fromNow()"
            :bg-color="
              item.has_read.find((read) => read === profile.sub) ? '' : 'lightblue'
            "
            @click="readNotification(item.id)"
          >
            <template #media>
              <img
                v-if="itme.image === ''"
                :src="item.image"
                width="44"
                style="border-radius: 25px"
              />
              <f7-badge
                color="auto"
                v-else
                style="height: 44px; width: 44px; font-size: 16px; border-radius: 25px"
                >{{ $filtersGetInitial.getInitial(participant.name) }}</f7-badge
              >
            </template>
            <f7-swipeout-actions right>
              <f7-swipeout-button
                v-if="item.link"
                color="blue"
                @click="goToLink(item.link)"
                >{{ $t("common.more") }}</f7-swipeout-button
              >
              <f7-swipeout-button
                @click="deleteNotification(item.id)"
                delete
                overswipe
                :confirm-text="$t('common.deleteNotificationConfirm')"
                >{{ $t("common.delete") }}</f7-swipeout-button
              >
            </f7-swipeout-actions>
          </f7-list-item>
        </f7-list>
      </div>
      <div class="right_layout"></div>
    </div>
  </f7-page>
</template>
<style scoped></style>

<script>
import {
  f7Page,
  f7Navbar,
  f7NavLeft,
  f7NavRight,
  f7NavTitle,
  f7Badge,
  f7Searchbar,
  f7List,
  f7ListItem,
  theme,
  f7,
} from "framework7-vue";
import { mapGetters, mapState } from "vuex";
import Navbarright from "../components/navbarright.vue";
import { messageSocket } from "../js/websocket/websocketConnect.js";
import _ from "lodash";
export default {
  props: {
    f7route: Object,
    f7router: Object,
  },
  components: {
    navbarright: Navbarright,
    f7Page,
    f7Navbar,
    f7NavLeft,
    f7NavRight,
    f7NavTitle,
    f7Searchbar,
    f7Badge,
    f7List,
    f7ListItem,
  },
  mixins: [messageSocket],
  data() {
    return {
      theme,
      f7,
      allowInfinite: true,
      showPreloader: false,
      visibleItem: 60,
    };
  },

  mounted() {
    // this.notificationsServiceConnect();
    if (!this.msgCltConnected) {
      this.connectMessageSocket();
    }
    if (this.notificationList && this.notificationList === "") {
      this.$store.dispatch("notification/getNotificationList", {
        limit: this.visibleItem,
        skip: this.notificationList.length,
      });
    }
  },
  methods: {
    deleteNotification(notificationId) {
      f7.preloader.show("multi");
      setTimeout(() => {
        this.$store.dispatch("notification/deleteNotification", notificationId);

        f7.preloader.hide();
      }, 1000);
      f7.preloader.hide();
    },
    readNotification(notificationId) {
      f7.preloader.show("multi");
      setTimeout(() => {
        this.$store.dispatch("notification/readNotification", notificationId);

        f7.preloader.hide();
      }, 1000);
      f7.preloader.hide();
    },
    goToLink(link) {
      this.$f7router.navigate(link);
    },

    loadMore(done) {
      setTimeout(() => {
        if (this.notificationList && this.notificationList.length === 0) {
          this.showPreloader = false;
          return;
        }
        this.showPreloader = true;
        const itemsLength = this.notificationList.length;
        this.$store.dispatch("notification/getNotificationList", {
          limit: this.visibleItem,
          skip: itemsLength,
        });
        done();
      }, 1000);
    },
    loadMoreScroll() {
      if (!this.allowInfinite) return;
      this.allowInfinite = false;
      const loadMoreThreshold = 30;
      setTimeout(() => {
        if (this.newNotifList && this.newNotifList.length == 0) {
          self.allowInfinite = false;
          return;
        }
        const itemsLength = this.notificationList.length;
        this.visibleItem += loadMoreThreshold;
        this.$store.dispatch("notification/getNotificationList", {
          limit: this.visibleItem,
          skip: itemsLength,
        });
        this.allowInfinite = true;
      }, 1000);
    },
    pageInit() {
      if (this.notificationList === "") {
        this.$store.dispatch("notification/getNotificationList", {
          limit: this.visibleItem,
          skip: this.notificationList.length,
        });
      }
    },
  },

  computed: {
    ...mapState("notification", ["notificationList", "newNotifList"]),
    ...mapState("message", ["messageSocketClient", "msgCltConnected"]),
    ...mapState("account", ["userAccount", "userDetail", "searchedUser"]),
    ...mapState("auth", ["phoneNumberDetail", "mobilePhoneError", "profile", "isTablet"]),
  },
};
</script>
